:root {
  --space-unit: 1em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}
:root {
  --max-width-xxs: 32rem;
  --max-width-xs: 38rem;
  --max-width-sm: 48rem;
  --max-width-md: 64rem;
  --max-width-lg: 80rem;
  --max-width-xl: 90rem;
  --max-width-xxl: 120rem;
}
.container {
  /* width: calc(100% - 1.25em);
    width:calc(100% - 2*var(--component-padding)); */
  margin-left: auto;
  margin-right: auto;
}
.max-width-xxs {
  max-width: 32rem;
  max-width: var(--max-width-xxs);
}
.max-width-xs {
  max-width: 38rem;
  max-width: var(--max-width-xs);
}
.max-width-sm {
  max-width: 48rem;
  max-width: var(--max-width-sm);
}
.max-width-md {
  max-width: 64rem;
  max-width: var(--max-width-md);
}
.max-width-lg {
  max-width: 80rem;
  max-width: var(--max-width-lg);
}
.max-width-xl {
  max-width: 90rem;
  max-width: var(--max-width-xl);
}
.max-width-xxl {
  max-width: 120rem;
  max-width: var(--max-width-xxl);
}
.max-width-adaptive-sm {
  max-width: 38rem;
  max-width: var(--max-width-xs);
}

@media (min-width: 64rem) {
  .max-width-adaptive-sm {
    max-width: 48rem;
    max-width: var(--max-width-sm);
  }
}
.max-width-adaptive-md {
  max-width: 38rem;
  max-width: var(--max-width-xs);
}

@media (min-width: 64rem) {
  .max-width-adaptive-md {
    max-width: 64rem;
    max-width: var(--max-width-md);
  }
}
.max-width-adaptive,
.max-width-adaptive-lg {
  max-width: 38rem;
  max-width: var(--max-width-xs);
}

@media (min-width: 64rem) {
  .max-width-adaptive,
  .max-width-adaptive-lg {
    max-width: 64rem;
    max-width: var(--max-width-md);
  }
}

@media (min-width: 90rem) {
  .max-width-adaptive,
  .max-width-adaptive-lg {
    max-width: 80rem;
    max-width: var(--max-width-lg);
  }
}
.max-width-adaptive-xl {
  max-width: 38rem;
  max-width: var(--max-width-xs);
}

@media (min-width: 64rem) {
  .max-width-adaptive-xl {
    max-width: 64rem;
    max-width: var(--max-width-md);
  }
}

@media (min-width: 90rem) {
  .max-width-adaptive-xl {
    max-width: 90rem;
    max-width: var(--max-width-xl);
  }
}
.grid {
  --grid-gap: 0px;
  display: flex;
  flex-wrap: wrap;
}
.grid > * {
  flex-basis: 100%;
}
.grid-gap-xxxxs {
  --grid-gap: var(--space-xxxxs);
}
.grid-gap-xxxs {
  --grid-gap: var(--space-xxxs);
}
.grid-gap-xxs {
  --grid-gap: var(--space-xxs);
}
.grid-gap-xs {
  --grid-gap: var(--space-xs);
}
.grid-gap-sm {
  --grid-gap: var(--space-sm);
}
.grid-gap-md {
  --grid-gap: var(--space-md);
}
.grid-gap-lg {
  --grid-gap: var(--space-lg);
}
.grid-gap-xl {
  --grid-gap: var(--space-xl);
}
.grid-gap-xxl {
  --grid-gap: var(--space-xxl);
}
.grid-gap-xxxl {
  --grid-gap: var(--space-xxxl);
}
.grid-gap-xxxxl {
  --grid-gap: var(--space-xxxxl);
}
.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.col-1 {
  flex-basis: calc(8.33% - 0.01px - 1em);
  flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(8.33% - 0.01px - 1em);
  max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
}
.col-2 {
  flex-basis: calc(16.66% - 0.01px - 1em);
  flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(16.66% - 0.01px - 1em);
  max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
}
.col-3 {
  flex-basis: calc(25% - 0.01px - 1em);
  flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(25% - 0.01px - 1em);
  max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
}
.col-4 {
  flex-basis: calc(33.33% - 0.01px - 1em);
  flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(33.33% - 0.01px - 1em);
  max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
}
.col-5 {
  flex-basis: calc(41.66% - 0.01px - 1em);
  flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(41.66% - 0.01px - 1em);
  max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
}
.col-6 {
  flex-basis: calc(50% - 0.01px - 1em);
  flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(50% - 0.01px - 1em);
  max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
}
.col-7 {
  flex-basis: calc(58.33% - 0.01px - 1em);
  flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(58.33% - 0.01px - 1em);
  max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
}
.col-8 {
  flex-basis: calc(66.66% - 0.01px - 1em);
  flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(66.66% - 0.01px - 1em);
  max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
}
.col-9 {
  flex-basis: calc(75% - 0.01px - 1em);
  flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(75% - 0.01px - 1em);
  max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
}
.col-10 {
  flex-basis: calc(83.33% - 0.01px - 1em);
  flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(83.33% - 0.01px - 1em);
  max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
}
.col-11 {
  flex-basis: calc(91.66% - 0.01px - 1em);
  flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(91.66% - 0.01px - 1em);
  max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
}
.col-12 {
  flex-basis: calc(100% - 0.01px - 1em);
  flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(100% - 0.01px - 1em);
  max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
}

@media (min-width: 32rem) {
  .col\@xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@xs {
    flex-basis: calc(8.33% - 0.01px - 1em);
    flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(8.33% - 0.01px - 1em);
    max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@xs {
    flex-basis: calc(16.66% - 0.01px - 1em);
    flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(16.66% - 0.01px - 1em);
    max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@xs {
    flex-basis: calc(25% - 0.01px - 1em);
    flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(25% - 0.01px - 1em);
    max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@xs {
    flex-basis: calc(33.33% - 0.01px - 1em);
    flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(33.33% - 0.01px - 1em);
    max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@xs {
    flex-basis: calc(41.66% - 0.01px - 1em);
    flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(41.66% - 0.01px - 1em);
    max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@xs {
    flex-basis: calc(50% - 0.01px - 1em);
    flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(50% - 0.01px - 1em);
    max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@xs {
    flex-basis: calc(58.33% - 0.01px - 1em);
    flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(58.33% - 0.01px - 1em);
    max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@xs {
    flex-basis: calc(66.66% - 0.01px - 1em);
    flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(66.66% - 0.01px - 1em);
    max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@xs {
    flex-basis: calc(75% - 0.01px - 1em);
    flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(75% - 0.01px - 1em);
    max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@xs {
    flex-basis: calc(83.33% - 0.01px - 1em);
    flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(83.33% - 0.01px - 1em);
    max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@xs {
    flex-basis: calc(91.66% - 0.01px - 1em);
    flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(91.66% - 0.01px - 1em);
    max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@xs {
    flex-basis: calc(100% - 0.01px - 1em);
    flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(100% - 0.01px - 1em);
    max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
  }
}

@media (min-width: 48rem) {
  .col\@sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@sm {
    flex-basis: calc(8.33% - 0.01px - 1em);
    flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(8.33% - 0.01px - 1em);
    max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@sm {
    flex-basis: calc(16.66% - 0.01px - 1em);
    flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(16.66% - 0.01px - 1em);
    max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@sm {
    flex-basis: calc(25% - 0.01px - 1em);
    flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(25% - 0.01px - 1em);
    max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@sm {
    flex-basis: calc(33.33% - 0.01px - 1em);
    flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(33.33% - 0.01px - 1em);
    max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@sm {
    flex-basis: calc(41.66% - 0.01px - 1em);
    flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(41.66% - 0.01px - 1em);
    max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@sm {
    flex-basis: calc(50% - 0.01px - 1em);
    flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(50% - 0.01px - 1em);
    max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@sm {
    flex-basis: calc(58.33% - 0.01px - 1em);
    flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(58.33% - 0.01px - 1em);
    max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@sm {
    flex-basis: calc(66.66% - 0.01px - 1em);
    flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(66.66% - 0.01px - 1em);
    max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@sm {
    flex-basis: calc(75% - 0.01px - 1em);
    flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(75% - 0.01px - 1em);
    max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@sm {
    flex-basis: calc(83.33% - 0.01px - 1em);
    flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(83.33% - 0.01px - 1em);
    max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@sm {
    flex-basis: calc(91.66% - 0.01px - 1em);
    flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(91.66% - 0.01px - 1em);
    max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@sm {
    flex-basis: calc(100% - 0.01px - 1em);
    flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(100% - 0.01px - 1em);
    max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
  }
}

@media (min-width: 64rem) {
  .col\@md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@md {
    flex-basis: calc(8.33% - 0.01px - 1em);
    flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(8.33% - 0.01px - 1em);
    max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@md {
    flex-basis: calc(16.66% - 0.01px - 1em);
    flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(16.66% - 0.01px - 1em);
    max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@md {
    flex-basis: calc(25% - 0.01px - 1em);
    flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(25% - 0.01px - 1em);
    max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@md {
    flex-basis: calc(33.33% - 0.01px - 1em);
    flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(33.33% - 0.01px - 1em);
    max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@md {
    flex-basis: calc(41.66% - 0.01px - 1em);
    flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(41.66% - 0.01px - 1em);
    max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@md {
    flex-basis: calc(50% - 0.01px - 1em);
    flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(50% - 0.01px - 1em);
    max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@md {
    flex-basis: calc(58.33% - 0.01px - 1em);
    flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(58.33% - 0.01px - 1em);
    max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@md {
    flex-basis: calc(66.66% - 0.01px - 1em);
    flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(66.66% - 0.01px - 1em);
    max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@md {
    flex-basis: calc(75% - 0.01px - 1em);
    flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(75% - 0.01px - 1em);
    max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@md {
    flex-basis: calc(83.33% - 0.01px - 1em);
    flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(83.33% - 0.01px - 1em);
    max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@md {
    flex-basis: calc(91.66% - 0.01px - 1em);
    flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(91.66% - 0.01px - 1em);
    max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@md {
    flex-basis: calc(100% - 0.01px - 1em);
    flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(100% - 0.01px - 1em);
    max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
  }
}

@media (min-width: 80rem) {
  .col\@lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@lg {
    flex-basis: calc(8.33% - 0.01px - 1em);
    flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(8.33% - 0.01px - 1em);
    max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@lg {
    flex-basis: calc(16.66% - 0.01px - 1em);
    flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(16.66% - 0.01px - 1em);
    max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@lg {
    flex-basis: calc(25% - 0.01px - 1em);
    flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(25% - 0.01px - 1em);
    max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@lg {
    flex-basis: calc(33.33% - 0.01px - 1em);
    flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(33.33% - 0.01px - 1em);
    max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@lg {
    flex-basis: calc(41.66% - 0.01px - 1em);
    flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(41.66% - 0.01px - 1em);
    max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@lg {
    flex-basis: calc(50% - 0.01px - 1em);
    flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(50% - 0.01px - 1em);
    max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@lg {
    flex-basis: calc(58.33% - 0.01px - 1em);
    flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(58.33% - 0.01px - 1em);
    max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@lg {
    flex-basis: calc(66.66% - 0.01px - 1em);
    flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(66.66% - 0.01px - 1em);
    max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@lg {
    flex-basis: calc(75% - 0.01px - 1em);
    flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(75% - 0.01px - 1em);
    max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@lg {
    flex-basis: calc(83.33% - 0.01px - 1em);
    flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(83.33% - 0.01px - 1em);
    max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@lg {
    flex-basis: calc(91.66% - 0.01px - 1em);
    flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(91.66% - 0.01px - 1em);
    max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@lg {
    flex-basis: calc(100% - 0.01px - 1em);
    flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(100% - 0.01px - 1em);
    max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
  }
}

@media (min-width: 90rem) {
  .col\@xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@xl {
    flex-basis: calc(8.33% - 0.01px - 1em);
    flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(8.33% - 0.01px - 1em);
    max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@xl {
    flex-basis: calc(16.66% - 0.01px - 1em);
    flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(16.66% - 0.01px - 1em);
    max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@xl {
    flex-basis: calc(25% - 0.01px - 1em);
    flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(25% - 0.01px - 1em);
    max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@xl {
    flex-basis: calc(33.33% - 0.01px - 1em);
    flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(33.33% - 0.01px - 1em);
    max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@xl {
    flex-basis: calc(41.66% - 0.01px - 1em);
    flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(41.66% - 0.01px - 1em);
    max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@xl {
    flex-basis: calc(50% - 0.01px - 1em);
    flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(50% - 0.01px - 1em);
    max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@xl {
    flex-basis: calc(58.33% - 0.01px - 1em);
    flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(58.33% - 0.01px - 1em);
    max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@xl {
    flex-basis: calc(66.66% - 0.01px - 1em);
    flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(66.66% - 0.01px - 1em);
    max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@xl {
    flex-basis: calc(75% - 0.01px - 1em);
    flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(75% - 0.01px - 1em);
    max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@xl {
    flex-basis: calc(83.33% - 0.01px - 1em);
    flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(83.33% - 0.01px - 1em);
    max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@xl {
    flex-basis: calc(91.66% - 0.01px - 1em);
    flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(91.66% - 0.01px - 1em);
    max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@xl {
    flex-basis: calc(100% - 0.01px - 1em);
    flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(100% - 0.01px - 1em);
    max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
  }
}
:root {
  --radius-sm: calc(var(--radius, 0.25em) / 2);
  --radius-md: var(--radius, 0.25em);
  --radius-lg: calc(var(--radius, 0.25em) * 2);
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.085), 0 1px 8px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 1px 8px rgba(0, 0, 0, 0.1), 0 8px 24px rgba(0, 0, 0, 0.15);
  --shadow-lg: 0 1px 8px rgba(0, 0, 0, 0.1), 0 16px 48px rgba(0, 0, 0, 0.1),
    0 24px 60px rgba(0, 0, 0, 0.1);
  --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
}
:root {
  --body-line-height: 1.4;
  --heading-line-height: 1.2;
}
.text-xxxl {
  font-size: 2.48832em;
  font-size: var(--text-xxxl, 2.488em);
}
.text-xxl {
  font-size: 2.0736em;
  font-size: var(--text-xxl, 2.074em);
}
.text-xl {
  font-size: 1.25rem;
  font-size: 1.5rem;
}
.text-lg {
  font-size: 1.44em;
  font-size: var(--text-lg, 1.44em);
}
.text-md {
  font-size: 1.2em;
  font-size: var(--text-md, 1.2em);
}
.text-sm {
  font-size: 0.83333em;
  font-size: var(--text-sm, 0.833em);
}
.text-xs {
  font-size: 0.69444em;
  font-size: var(--text-xs, 0.694em);
}
.text-underline {
  text-decoration: underline;
}
.text-component {
  --component-body-line-height: calc(
    var(--body-line-height) * var(--line-height-multiplier, 1)
  );
  --component-heading-line-height: calc(
    var(--heading-line-height) * var(--line-height-multiplier, 1)
  );
}
.text-component h1,
.text-component h2,
.text-component h3,
.text-component h4 {
  line-height: 1.2;
  line-height: var(--component-heading-line-height, 1.2);
  margin-bottom: 0.25em;
  margin-bottom: calc(var(--space-xxxs) * var(--text-vspace-multiplier, 1));
}
.text-component h2,
.text-component h3,
.text-component h4 {
  margin-top: 0.75em;
  margin-top: calc(var(--space-sm) * var(--text-vspace-multiplier, 1));
}
.text-component p,
.text-component blockquote,
.text-component ul li,
.text-component ol li {
  line-height: 1.4;
  line-height: var(--component-body-line-height);
}
.text-component p {
  line-height: inherit;
}
.text-component ul,
.text-component ol,
.text-component p,
.text-component blockquote,
.text-component .text-component__block {
  margin-bottom: 0.75em;
  margin-bottom: calc(var(--space-sm) * var(--text-vspace-multiplier, 0.75));
}
.text-component ul,
.text-component ol {
  padding-left: 1em;
}
.text-component ul {
  list-style-type: disc;
}
.text-component ol {
  list-style-type: decimal;
}
.text-component img {
  display: block;
  margin: 0 auto;
}
.text-component figcaption {
  text-align: center;
  margin-top: 0.5em;
  margin-top: var(--space-xs);
}
.text-component em {
  font-style: italic;
}
.text-component hr {
  margin-top: 2em;
  margin-top: calc(var(--space-lg) * var(--text-vspace-multiplier, 1));
  margin-bottom: 2em;
  margin-bottom: calc(var(--space-lg) * var(--text-vspace-multiplier, 1));
  margin-left: auto;
  margin-right: auto;
}
.text-component > *:first-child {
  margin-top: 0;
}
.text-component > *:last-child {
  margin-bottom: 0;
}
.text-component__block--full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@media (min-width: 48rem) {
  .text-component__block--left,
  .text-component__block--right {
    width: 45%;
  }
  .text-component__block--left img,
  .text-component__block--right img {
    width: 100%;
  }
  .text-component__block--left {
    float: left;
    margin-right: 0.75em;
    margin-right: calc(var(--space-sm) * var(--text-vspace-multiplier, 1));
  }
  .text-component__block--right {
    float: right;
    margin-left: 0.75em;
    margin-left: calc(var(--space-sm) * var(--text-vspace-multiplier, 1));
  }
}

@media (min-width: 90rem) {
  .text-component__block--outset {
    width: calc(100% + 10.5em);
    width: calc(100% + 2 * var(--space-xxl));
  }
  .text-component__block--outset img {
    width: 100%;
  }
  .text-component__block--outset:not(.text-component__block--right) {
    margin-left: -5.25em;
    margin-left: calc(-1 * var(--space-xxl));
  }
  .text-component__block--left,
  .text-component__block--right {
    width: 50%;
  }
  .text-component__block--right.text-component__block--outset {
    margin-right: -5.25em;
    margin-right: calc(-1 * var(--space-xxl));
  }
}
:root {
  --icon-xxs: 12px;
  --icon-xs: 16px;
  --icon-sm: 24px;
  --icon-md: 32px;
  --icon-lg: 48px;
  --icon-xl: 64px;
  --icon-xxl: 128px;
}
.icon {
  display: inline-block;
  color: inherit;
  fill: currentColor;
  height: 1em;
  width: 1em;
  line-height: 1;
  flex-shrink: 0;
}
.icon--xxs {
  font-size: 12px;
  font-size: var(--icon-xxs);
}
.icon--xs {
  font-size: 16px;
  font-size: var(--icon-xs);
}
.icon--sm {
  font-size: 24px;
  font-size: var(--icon-sm);
}
.icon--md {
  font-size: 32px;
  font-size: var(--icon-md);
}
.icon--lg {
  font-size: 48px;
  font-size: var(--icon-lg);
}
.icon--xl {
  font-size: 64px;
  font-size: var(--icon-xl);
}
.icon--xxl {
  font-size: 128px;
  font-size: var(--icon-xxl);
}
.icon--is-spinning {
  animation: icon-spin 1s infinite linear;
}
@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon use {
  color: inherit;
  fill: currentColor;
}
.radio-list > *,
.checkbox-list > * {
  position: relative;
  display: flex;
  align-items: baseline;
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
}
.radio-list > *:last-of-type,
.checkbox-list > *:last-of-type {
  margin-bottom: 0;
}
.radio-list label,
.checkbox-list label {
  line-height: 1.4;
  line-height: var(--body-line-height);
  -webkit-user-select: none;
  user-select: none;
}
.radio-list input,
.checkbox-list input {
  vertical-align: top;
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
  flex-shrink: 0;
}
:root {
  --zindex-header: 2;
  --zindex-popover: 5;
  --zindex-fixed-element: 10;
  --zindex-overlay: 15;
}

@media not all and (min-width: 32rem) {
  .display\@xs {
    display: none !important;
  }
}

@media (min-width: 32rem) {
  .hide\@xs {
    display: none !important;
  }
}

@media not all and (min-width: 48rem) {
  .display\@sm {
    display: none !important;
  }
}

@media (min-width: 48rem) {
  .hide\@sm {
    display: none !important;
  }
}

@media not all and (min-width: 64rem) {
  .display\@md {
    display: none !important;
  }
}

@media (min-width: 64rem) {
  .hide\@md {
    display: none !important;
  }
}

@media not all and (min-width: 80rem) {
  .display\@lg {
    display: none !important;
  }
}

@media (min-width: 80rem) {
  .hide\@lg {
    display: none !important;
  }
}

@media not all and (min-width: 90rem) {
  .display\@xl {
    display: none !important;
  }
}

@media (min-width: 90rem) {
  .hide\@xl {
    display: none !important;
  }
}
:root {
  --display: block;
}
.is-visible {
  display: block !important;
  display: var(--display) !important;
}
.is-hidden {
  display: none !important;
}
.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}

@media (min-width: 32rem) {
  .flex-wrap\@xs {
    flex-wrap: wrap;
  }
  .flex-column\@xs {
    flex-direction: column;
  }
  .flex-row\@xs {
    flex-direction: row;
  }
  .flex-center\@xs {
    justify-content: center;
    align-items: center;
  }
  .justify-start\@xs {
    justify-content: flex-start;
  }
  .justify-end\@xs {
    justify-content: flex-end;
  }
  .justify-center\@xs {
    justify-content: center;
  }
  .justify-between\@xs {
    justify-content: space-between;
  }
  .items-center\@xs {
    align-items: center;
  }
  .items-start\@xs {
    align-items: flex-start;
  }
  .items-end\@xs {
    align-items: flex-end;
  }
}

@media (min-width: 48rem) {
  .flex-wrap\@sm {
    flex-wrap: wrap;
  }
  .flex-column\@sm {
    flex-direction: column;
  }
  .flex-row\@sm {
    flex-direction: row;
  }
  .flex-center\@sm {
    justify-content: center;
    align-items: center;
  }
  .justify-start\@sm {
    justify-content: flex-start;
  }
  .justify-end\@sm {
    justify-content: flex-end;
  }
  .justify-center\@sm {
    justify-content: center;
  }
  .justify-between\@sm {
    justify-content: space-between;
  }
  .items-center\@sm {
    align-items: center;
  }
  .items-start\@sm {
    align-items: flex-start;
  }
  .items-end\@sm {
    align-items: flex-end;
  }
}

@media (min-width: 64rem) {
  .flex-wrap\@md {
    flex-wrap: wrap;
  }
  .flex-column\@md {
    flex-direction: column;
  }
  .flex-row\@md {
    flex-direction: row;
  }
  .flex-center\@md {
    justify-content: center;
    align-items: center;
  }
  .justify-start\@md {
    justify-content: flex-start;
  }
  .justify-end\@md {
    justify-content: flex-end;
  }
  .justify-center\@md {
    justify-content: center;
  }
  .justify-between\@md {
    justify-content: space-between;
  }
  .items-center\@md {
    align-items: center;
  }
  .items-start\@md {
    align-items: flex-start;
  }
  .items-end\@md {
    align-items: flex-end;
  }
}

@media (min-width: 80rem) {
  .flex-wrap\@lg {
    flex-wrap: wrap;
  }
  .flex-column\@lg {
    flex-direction: column;
  }
  .flex-row\@lg {
    flex-direction: row;
  }
  .flex-center\@lg {
    justify-content: center;
    align-items: center;
  }
  .justify-start\@lg {
    justify-content: flex-start;
  }
  .justify-end\@lg {
    justify-content: flex-end;
  }
  .justify-center\@lg {
    justify-content: center;
  }
  .justify-between\@lg {
    justify-content: space-between;
  }
  .items-center\@lg {
    align-items: center;
  }
  .items-start\@lg {
    align-items: flex-start;
  }
  .items-end\@lg {
    align-items: flex-end;
  }
}

@media (min-width: 90rem) {
  .flex-wrap\@xl {
    flex-wrap: wrap;
  }
  .flex-column\@xl {
    flex-direction: column;
  }
  .flex-row\@xl {
    flex-direction: row;
  }
  .flex-center\@xl {
    justify-content: center;
    align-items: center;
  }
  .justify-start\@xl {
    justify-content: flex-start;
  }
  .justify-end\@xl {
    justify-content: flex-end;
  }
  .justify-center\@xl {
    justify-content: center;
  }
  .justify-between\@xl {
    justify-content: space-between;
  }
  .items-center\@xl {
    align-items: center;
  }
  .items-start\@xl {
    align-items: flex-start;
  }
  .items-end\@xl {
    align-items: flex-end;
  }
}
.flex-grow {
  flex-grow: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-gap-xxxs {
  margin-bottom: -0.25em;
  margin-bottom: calc(-1 * var(--space-xxxs));
  margin-right: -0.25em;
  margin-right: calc(-1 * var(--space-xxxs));
}
.flex-gap-xxxs > * {
  margin-bottom: 0.25em;
  margin-bottom: var(--space-xxxs);
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
}
.flex-gap-xxs {
  margin-bottom: -0.375em;
  margin-bottom: calc(-1 * var(--space-xxs));
  margin-right: -0.375em;
  margin-right: calc(-1 * var(--space-xxs));
}
.flex-gap-xxs > * {
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
  margin-right: 0.375em;
  margin-right: var(--space-xxs);
}
.flex-gap-xs {
  margin-bottom: -0.5em;
  margin-bottom: calc(-1 * var(--space-xs));
  margin-right: -0.5em;
  margin-right: calc(-1 * var(--space-xs));
}
.flex-gap-xs > * {
  margin-bottom: 0.5em;
  margin-bottom: var(--space-xs);
  margin-right: 0.5em;
  margin-right: var(--space-xs);
}
.flex-gap-sm {
  margin-bottom: -0.75em;
  margin-bottom: calc(-1 * var(--space-sm));
  margin-right: -0.75em;
  margin-right: calc(-1 * var(--space-sm));
}
.flex-gap-sm > * {
  margin-bottom: 0.75em;
  margin-bottom: var(--space-sm);
  margin-right: 0.75em;
  margin-right: var(--space-sm);
}
.flex-gap-md {
  margin-bottom: -1.25em;
  margin-bottom: calc(-1 * var(--space-md));
  margin-right: -1.25em;
  margin-right: calc(-1 * var(--space-md));
}
.flex-gap-md > * {
  margin-bottom: 1.25em;
  margin-bottom: var(--space-md);
  margin-right: 1.25em;
  margin-right: var(--space-md);
}
.flex-gap-lg {
  margin-bottom: -2em;
  margin-bottom: calc(-1 * var(--space-lg));
  margin-right: -2em;
  margin-right: calc(-1 * var(--space-lg));
}
.flex-gap-lg > * {
  margin-bottom: 2em;
  margin-bottom: var(--space-lg);
  margin-right: 2em;
  margin-right: var(--space-lg);
}
.flex-gap-xl {
  margin-bottom: -3.25em;
  margin-bottom: calc(-1 * var(--space-xl));
  margin-right: -3.25em;
  margin-right: calc(-1 * var(--space-xl));
}
.flex-gap-xl > * {
  margin-bottom: 3.25em;
  margin-bottom: var(--space-xl);
  margin-right: 3.25em;
  margin-right: var(--space-xl);
}
.flex-gap-xxl {
  margin-bottom: -5.25em;
  margin-bottom: calc(-1 * var(--space-xxl));
  margin-right: -5.25em;
  margin-right: calc(-1 * var(--space-xxl));
}
.flex-gap-xxl > * {
  margin-bottom: 5.25em;
  margin-bottom: var(--space-xxl);
  margin-right: 5.25em;
  margin-right: var(--space-xxl);
}
.margin-xxxxs {
  margin: 0.125em;
  margin: var(--space-xxxxs);
}
.margin-xxxs {
  margin: 0.25em;
  margin: var(--space-xxxs);
}
.margin-xxs {
  margin: 0.375em;
  margin: var(--space-xxs);
}
.margin-xs {
  margin: 0.5em;
  margin: var(--space-xs);
}
.margin-sm {
  margin: 0.75em;
  margin: var(--space-sm);
}
.margin-md {
  margin: 1.25em;
  margin: var(--space-md);
}
.margin-lg {
  margin: 2em;
  margin: var(--space-lg);
}
.margin-xl {
  margin: 3.25em;
  margin: var(--space-xl);
}
.margin-xxl {
  margin: 5.25em;
  margin: var(--space-xxl);
}
.margin-xxxl {
  margin: 8.5em;
  margin: var(--space-xxxl);
}
.margin-xxxxl {
  margin: 13.75em;
  margin: var(--space-xxxxl);
}
.margin-auto {
  margin: auto;
}
.margin-top-xxxxs {
  margin-top: 0.125em;
  margin-top: var(--space-xxxxs);
}
.margin-top-xxxs {
  margin-top: 0.25em;
  margin-top: var(--space-xxxs);
}
.margin-top-xxs {
  margin-top: 0.375em;
  margin-top: var(--space-xxs);
}
.margin-top-xs {
  margin-top: 0.5em;
  margin-top: var(--space-xs);
}
.margin-top-sm {
  margin-top: 0.75em;
  margin-top: var(--space-sm);
}
.margin-top-md {
  margin-top: 1.25em;
  margin-top: var(--space-md);
}
.margin-top-lg {
  margin-top: 2em;
  margin-top: var(--space-lg);
}
.margin-top-xl {
  margin-top: 3.25em;
  margin-top: var(--space-xl);
}
.margin-top-xxl {
  margin-top: 5.25em;
  margin-top: var(--space-xxl);
}
.margin-top-xxxl {
  margin-top: 8.5em;
  margin-top: var(--space-xxxl);
}
.margin-top-xxxxl {
  margin-top: 13.75em;
  margin-top: var(--space-xxxxl);
}
.margin-top-auto {
  margin-top: auto;
}
.margin-bottom-xxxxs {
  margin-bottom: 0.125em;
  margin-bottom: var(--space-xxxxs);
}
.margin-bottom-xxxs {
  margin-bottom: 0.25em;
  margin-bottom: var(--space-xxxs);
}
.margin-bottom-xxs {
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
}
.margin-bottom-xs {
  margin-bottom: 0.5em;
  margin-bottom: var(--space-xs);
}
.margin-bottom-sm {
  margin-bottom: 0.75em;
  margin-bottom: var(--space-sm);
}
.margin-bottom-md {
  margin-bottom: 1.25em;
  margin-bottom: var(--space-md);
}
.margin-bottom-lg {
  margin-bottom: 2em;
  margin-bottom: var(--space-lg);
}
.margin-bottom-xl {
  margin-bottom: 3.25em;
  margin-bottom: var(--space-xl);
}
.margin-bottom-xxl {
  margin-bottom: 5.25em;
  margin-bottom: var(--space-xxl);
}
.margin-bottom-xxxl {
  margin-bottom: 8.5em;
  margin-bottom: var(--space-xxxl);
}
.margin-bottom-xxxxl {
  margin-bottom: 13.75em;
  margin-bottom: var(--space-xxxxl);
}
.margin-bottom-auto {
  margin-bottom: auto;
}
.margin-right-xxxxs {
  margin-right: 0.125em;
  margin-right: var(--space-xxxxs);
}
.margin-right-xxxs {
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
}
.margin-right-xxs {
  margin-right: 0.375em;
  margin-right: var(--space-xxs);
}
.margin-right-xs {
  margin-right: 0.5em;
  margin-right: var(--space-xs);
}
.margin-right-sm {
  margin-right: 0.75em;
  margin-right: var(--space-sm);
}
.margin-right-md {
  margin-right: 1.25em;
  margin-right: var(--space-md);
}
.margin-right-lg {
  margin-right: 2em;
  margin-right: var(--space-lg);
}
.margin-right-xl {
  margin-right: 3.25em;
  margin-right: var(--space-xl);
}
.margin-right-xxl {
  margin-right: 5.25em;
  margin-right: var(--space-xxl);
}
.margin-right-xxxl {
  margin-right: 8.5em;
  margin-right: var(--space-xxxl);
}
.margin-right-xxxxl {
  margin-right: 13.75em;
  margin-right: var(--space-xxxxl);
}
.margin-right-auto {
  margin-right: auto;
}
.margin-left-xxxxs {
  margin-left: 0.125em;
  margin-left: var(--space-xxxxs);
}
.margin-left-xxxs {
  margin-left: 0.25em;
  margin-left: var(--space-xxxs);
}
.margin-left-xxs {
  margin-left: 0.375em;
  margin-left: var(--space-xxs);
}
.margin-left-xs {
  margin-left: 0.5em;
  margin-left: var(--space-xs);
}
.margin-left-sm {
  margin-left: 0.75em;
  margin-left: var(--space-sm);
}
.margin-left-md {
  margin-left: 1.25em;
  margin-left: var(--space-md);
}
.margin-left-lg {
  margin-left: 2em;
  margin-left: var(--space-lg);
}
.margin-left-xl {
  margin-left: 3.25em;
  margin-left: var(--space-xl);
}
.margin-left-xxl {
  margin-left: 5.25em;
  margin-left: var(--space-xxl);
}
.margin-left-xxxl {
  margin-left: 8.5em;
  margin-left: var(--space-xxxl);
}
.margin-left-xxxxl {
  margin-left: 13.75em;
  margin-left: var(--space-xxxxl);
}
.margin-left-auto {
  margin-left: auto;
}
.margin-x-xxxxs {
  margin-left: 0.125em;
  margin-left: var(--space-xxxxs);
  margin-right: 0.125em;
  margin-right: var(--space-xxxxs);
}
.margin-x-xxxs {
  margin-left: 0.25em;
  margin-left: var(--space-xxxs);
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
}
.margin-x-xxs {
  margin-left: 0.375em;
  margin-left: var(--space-xxs);
  margin-right: 0.375em;
  margin-right: var(--space-xxs);
}
.margin-x-xs {
  margin-left: 0.5em;
  margin-left: var(--space-xs);
  margin-right: 0.5em;
  margin-right: var(--space-xs);
}
.margin-x-sm {
  margin-left: 0.75em;
  margin-left: var(--space-sm);
  margin-right: 0.75em;
  margin-right: var(--space-sm);
}
.margin-x-md {
  margin-left: 1.25em;
  margin-left: var(--space-md);
  margin-right: 1.25em;
  margin-right: var(--space-md);
}
.margin-x-lg {
  margin-left: 2em;
  margin-left: var(--space-lg);
  margin-right: 2em;
  margin-right: var(--space-lg);
}
.margin-x-xl {
  margin-left: 3.25em;
  margin-left: var(--space-xl);
  margin-right: 3.25em;
  margin-right: var(--space-xl);
}
.margin-x-xxl {
  margin-left: 5.25em;
  margin-left: var(--space-xxl);
  margin-right: 5.25em;
  margin-right: var(--space-xxl);
}
.margin-x-xxxl {
  margin-left: 8.5em;
  margin-left: var(--space-xxxl);
  margin-right: 8.5em;
  margin-right: var(--space-xxxl);
}
.margin-x-xxxxl {
  margin-left: 13.75em;
  margin-left: var(--space-xxxxl);
  margin-right: 13.75em;
  margin-right: var(--space-xxxxl);
}
.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-y-xxxxs {
  margin-top: 0.125em;
  margin-top: var(--space-xxxxs);
  margin-bottom: 0.125em;
  margin-bottom: var(--space-xxxxs);
}
.margin-y-xxxs {
  margin-top: 0.25em;
  margin-top: var(--space-xxxs);
  margin-bottom: 0.25em;
  margin-bottom: var(--space-xxxs);
}
.margin-y-xxs {
  margin-top: 0.375em;
  margin-top: var(--space-xxs);
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
}
.margin-y-xs {
  margin-top: 0.5em;
  margin-top: var(--space-xs);
  margin-bottom: 0.5em;
  margin-bottom: var(--space-xs);
}
.margin-y-sm {
  margin-top: 0.75em;
  margin-top: var(--space-sm);
  margin-bottom: 0.75em;
  margin-bottom: var(--space-sm);
}
.margin-y-md {
  margin-top: 1.25em;
  margin-top: var(--space-md);
  margin-bottom: 1.25em;
  margin-bottom: var(--space-md);
}
.margin-y-lg {
  margin-top: 2em;
  margin-top: var(--space-lg);
  margin-bottom: 2em;
  margin-bottom: var(--space-lg);
}
.margin-y-xl {
  margin-top: 3.25em;
  margin-top: var(--space-xl);
  margin-bottom: 3.25em;
  margin-bottom: var(--space-xl);
}
.margin-y-xxl {
  margin-top: 5.25em;
  margin-top: var(--space-xxl);
  margin-bottom: 5.25em;
  margin-bottom: var(--space-xxl);
}
.margin-y-xxxl {
  margin-top: 8.5em;
  margin-top: var(--space-xxxl);
  margin-bottom: 8.5em;
  margin-bottom: var(--space-xxxl);
}
.margin-y-xxxxl {
  margin-top: 13.75em;
  margin-top: var(--space-xxxxl);
  margin-bottom: 13.75em;
  margin-bottom: var(--space-xxxxl);
}
.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

@media not all and (min-width: 32rem) {
  .has-margin\@xs {
    margin: 0 !important;
  }
}

@media not all and (min-width: 48rem) {
  .has-margin\@sm {
    margin: 0 !important;
  }
}

@media not all and (min-width: 64rem) {
  .has-margin\@md {
    margin: 0 !important;
  }
}

@media not all and (min-width: 80rem) {
  .has-margin\@lg {
    margin: 0 !important;
  }
}

@media not all and (min-width: 90rem) {
  .has-margin\@xl {
    margin: 0 !important;
  }
}
.padding-md {
  padding: 1.25em;
  padding: var(--space-md);
}
.padding-xxxxs {
  padding: 0.125em;
  padding: var(--space-xxxxs);
}
.padding-xxxs {
  padding: 0.25em;
  padding: var(--space-xxxs);
}
.padding-xxs {
  padding: 0.375em;
  padding: var(--space-xxs);
}
.padding-xs {
  padding: 0.5em;
  padding: var(--space-xs);
}
.padding-sm {
  padding: 0.75em;
  padding: var(--space-sm);
}
.padding-lg {
  padding: 2em;
  padding: var(--space-lg);
}
.padding-xl {
  padding: 3.25em;
  padding: var(--space-xl);
}
.padding-xxl {
  padding: 5.25em;
  padding: var(--space-xxl);
}
.padding-xxxl {
  padding: 8.5em;
  padding: var(--space-xxxl);
}
.padding-xxxxl {
  padding: 13.75em;
  padding: var(--space-xxxxl);
}
.padding-component {
  padding: 1.25em;
  padding: var(--component-padding);
}
.padding-top-md {
  padding-top: 1.25em;
  padding-top: var(--space-md);
}
.padding-top-xxxxs {
  padding-top: 0.125em;
  padding-top: var(--space-xxxxs);
}
.padding-top-xxxs {
  padding-top: 0.25em;
  padding-top: var(--space-xxxs);
}
.padding-top-xxs {
  padding-top: 0.375em;
  padding-top: var(--space-xxs);
}
.padding-top-xs {
  padding-top: 0.5em;
  padding-top: var(--space-xs);
}
.padding-top-sm {
  padding-top: 0.75em;
  padding-top: var(--space-sm);
}
.padding-top-lg {
  padding-top: 2em;
  padding-top: var(--space-lg);
}
.padding-top-xl {
  padding-top: 3.25em;
  padding-top: var(--space-xl);
}
.padding-top-xxl {
  padding-top: 5.25em;
  padding-top: var(--space-xxl);
}
.padding-top-xxxl {
  padding-top: 8.5em;
  padding-top: var(--space-xxxl);
}
.padding-top-xxxxl {
  padding-top: 13.75em;
  padding-top: var(--space-xxxxl);
}
.padding-top-component {
  padding-top: 1.25em;
  padding-top: var(--component-padding);
}
.padding-bottom-md {
  padding-bottom: 1.25em;
  padding-bottom: var(--space-md);
}
.padding-bottom-xxxxs {
  padding-bottom: 0.125em;
  padding-bottom: var(--space-xxxxs);
}
.padding-bottom-xxxs {
  padding-bottom: 0.25em;
  padding-bottom: var(--space-xxxs);
}
.padding-bottom-xxs {
  padding-bottom: 0.375em;
  padding-bottom: var(--space-xxs);
}
.padding-bottom-xs {
  padding-bottom: 0.5em;
  padding-bottom: var(--space-xs);
}
.padding-bottom-sm {
  padding-bottom: 0.75em;
  padding-bottom: var(--space-sm);
}
.padding-bottom-lg {
  padding-bottom: 2em;
  padding-bottom: var(--space-lg);
}
.padding-bottom-xl {
  padding-bottom: 3.25em;
  padding-bottom: var(--space-xl);
}
.padding-bottom-xxl {
  padding-bottom: 5.25em;
  padding-bottom: var(--space-xxl);
}
.padding-bottom-xxxl {
  padding-bottom: 8.5em;
  padding-bottom: var(--space-xxxl);
}
.padding-bottom-xxxxl {
  padding-bottom: 13.75em;
  padding-bottom: var(--space-xxxxl);
}
.padding-bottom-component {
  padding-bottom: 1.25em;
  padding-bottom: var(--component-padding);
}
.padding-right-md {
  padding-right: 1.25em;
  padding-right: var(--space-md);
}
.padding-right-xxxxs {
  padding-right: 0.125em;
  padding-right: var(--space-xxxxs);
}
.padding-right-xxxs {
  padding-right: 0.25em;
  padding-right: var(--space-xxxs);
}
.padding-right-xxs {
  padding-right: 0.375em;
  padding-right: var(--space-xxs);
}
.padding-right-xs {
  padding-right: 0.5em;
  padding-right: var(--space-xs);
}
.padding-right-sm {
  padding-right: 0.75em;
  padding-right: var(--space-sm);
}
.padding-right-lg {
  padding-right: 2em;
  padding-right: var(--space-lg);
}
.padding-right-xl {
  padding-right: 3.25em;
  padding-right: var(--space-xl);
}
.padding-right-xxl {
  padding-right: 5.25em;
  padding-right: var(--space-xxl);
}
.padding-right-xxxl {
  padding-right: 8.5em;
  padding-right: var(--space-xxxl);
}
.padding-right-xxxxl {
  padding-right: 13.75em;
  padding-right: var(--space-xxxxl);
}
.padding-right-component {
  padding-right: 1.25em;
  padding-right: var(--component-padding);
}
.padding-left-md {
  padding-left: 1.25em;
  padding-left: var(--space-md);
}
.padding-left-xxxxs {
  padding-left: 0.125em;
  padding-left: var(--space-xxxxs);
}
.padding-left-xxxs {
  padding-left: 0.25em;
  padding-left: var(--space-xxxs);
}
.padding-left-xxs {
  padding-left: 0.375em;
  padding-left: var(--space-xxs);
}
.padding-left-xs {
  padding-left: 0.5em;
  padding-left: var(--space-xs);
}
.padding-left-sm {
  padding-left: 0.75em;
  padding-left: var(--space-sm);
}
.padding-left-lg {
  padding-left: 2em;
  padding-left: var(--space-lg);
}
.padding-left-xl {
  padding-left: 3.25em;
  padding-left: var(--space-xl);
}
.padding-left-xxl {
  padding-left: 5.25em;
  padding-left: var(--space-xxl);
}
.padding-left-xxxl {
  padding-left: 8.5em;
  padding-left: var(--space-xxxl);
}
.padding-left-xxxxl {
  padding-left: 13.75em;
  padding-left: var(--space-xxxxl);
}
.padding-left-component {
  padding-left: 1.25em;
  padding-left: var(--component-padding);
}
.padding-x-md {
  padding-left: 1.25em;
  padding-left: var(--space-md);
  padding-right: 1.25em;
  padding-right: var(--space-md);
}
.padding-x-xxxxs {
  padding-left: 0.125em;
  padding-left: var(--space-xxxxs);
  padding-right: 0.125em;
  padding-right: var(--space-xxxxs);
}
.padding-x-xxxs {
  padding-left: 0.25em;
  padding-left: var(--space-xxxs);
  padding-right: 0.25em;
  padding-right: var(--space-xxxs);
}
.padding-x-xxs {
  padding-left: 0.375em;
  padding-left: var(--space-xxs);
  padding-right: 0.375em;
  padding-right: var(--space-xxs);
}
.padding-x-xs {
  padding-left: 0.5em;
  padding-left: var(--space-xs);
  padding-right: 0.5em;
  padding-right: var(--space-xs);
}
.padding-x-sm {
  padding-left: 0.75em;
  padding-left: var(--space-sm);
  padding-right: 0.75em;
  padding-right: var(--space-sm);
}
.padding-x-lg {
  padding-left: 2em;
  padding-left: var(--space-lg);
  padding-right: 2em;
  padding-right: var(--space-lg);
}
.padding-x-xl {
  padding-left: 3.25em;
  padding-left: var(--space-xl);
  padding-right: 3.25em;
  padding-right: var(--space-xl);
}
.padding-x-xxl {
  padding-left: 5.25em;
  padding-left: var(--space-xxl);
  padding-right: 5.25em;
  padding-right: var(--space-xxl);
}
.padding-x-xxxl {
  padding-left: 8.5em;
  padding-left: var(--space-xxxl);
  padding-right: 8.5em;
  padding-right: var(--space-xxxl);
}
.padding-x-xxxxl {
  padding-left: 13.75em;
  padding-left: var(--space-xxxxl);
  padding-right: 13.75em;
  padding-right: var(--space-xxxxl);
}
.padding-x-component {
  padding-left: 1.25em;
  padding-left: var(--component-padding);
  padding-right: 1.25em;
  padding-right: var(--component-padding);
}
.padding-y-md {
  padding-top: 1.25em;
  padding-top: var(--space-md);
  padding-bottom: 1.25em;
  padding-bottom: var(--space-md);
}
.padding-y-xxxxs {
  padding-top: 0.125em;
  padding-top: var(--space-xxxxs);
  padding-bottom: 0.125em;
  padding-bottom: var(--space-xxxxs);
}
.padding-y-xxxs {
  padding-top: 0.25em;
  padding-top: var(--space-xxxs);
  padding-bottom: 0.25em;
  padding-bottom: var(--space-xxxs);
}
.padding-y-xxs {
  padding-top: 0.375em;
  padding-top: var(--space-xxs);
  padding-bottom: 0.375em;
  padding-bottom: var(--space-xxs);
}
.padding-y-xs {
  padding-top: 0.5em;
  padding-top: var(--space-xs);
  padding-bottom: 0.5em;
  padding-bottom: var(--space-xs);
}
.padding-y-sm {
  padding-top: 0.75em;
  padding-top: var(--space-sm);
  padding-bottom: 0.75em;
  padding-bottom: var(--space-sm);
}
.padding-y-lg {
  padding-top: 2em;
  padding-top: var(--space-lg);
  padding-bottom: 2em;
  padding-bottom: var(--space-lg);
}
.padding-y-xl {
  padding-top: 3.25em;
  padding-top: var(--space-xl);
  padding-bottom: 3.25em;
  padding-bottom: var(--space-xl);
}
.padding-y-xxl {
  padding-top: 5.25em;
  padding-top: var(--space-xxl);
  padding-bottom: 5.25em;
  padding-bottom: var(--space-xxl);
}
.padding-y-xxxl {
  padding-top: 8.5em;
  padding-top: var(--space-xxxl);
  padding-bottom: 8.5em;
  padding-bottom: var(--space-xxxl);
}
.padding-y-xxxxl {
  padding-top: 13.75em;
  padding-top: var(--space-xxxxl);
  padding-bottom: 13.75em;
  padding-bottom: var(--space-xxxxl);
}
.padding-y-component {
  padding-top: 1.25em;
  padding-top: var(--component-padding);
  padding-bottom: 1.25em;
  padding-bottom: var(--component-padding);
}

@media not all and (min-width: 32rem) {
  .has-padding\@xs {
    padding: 0 !important;
  }
}

@media not all and (min-width: 48rem) {
  .has-padding\@sm {
    padding: 0 !important;
  }
}

@media not all and (min-width: 64rem) {
  .has-padding\@md {
    padding: 0 !important;
  }
}

@media not all and (min-width: 80rem) {
  .has-padding\@lg {
    padding: 0 !important;
  }
}

@media not all and (min-width: 90rem) {
  .has-padding\@xl {
    padding: 0 !important;
  }
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

@media (min-width: 32rem) {
  .text-center\@xs {
    text-align: center;
  }
  .text-left\@xs {
    text-align: left;
  }
  .text-right\@xs {
    text-align: right;
  }
}

@media (min-width: 48rem) {
  .text-center\@sm {
    text-align: center;
  }
  .text-left\@sm {
    text-align: left;
  }
  .text-right\@sm {
    text-align: right;
  }
}

@media (min-width: 64rem) {
  .text-center\@md {
    text-align: center;
  }
  .text-left\@md {
    text-align: left;
  }
  .text-right\@md {
    text-align: right;
  }
}

@media (min-width: 80rem) {
  .text-center\@lg {
    text-align: center;
  }
  .text-left\@lg {
    text-align: left;
  }
  .text-right\@lg {
    text-align: right;
  }
}

@media (min-width: 90rem) {
  .text-center\@xl {
    text-align: center;
  }
  .text-left\@xl {
    text-align: left;
  }
  .text-right\@xl {
    text-align: right;
  }
}
.color-inherit {
  color: inherit;
}
.color-contrast-medium {
  color: inherit;
}
.color-contrast-high {
  color: #333;
  color: var(--color-contrast-high, #313135);
}
.color-contrast-higher {
  color: #222;
  color: var(--color-contrast-higher, #1c1c21);
}
.color-primary {
  color: var(--ocean);
  color: var(--color-primary, #2a6df4);
}
.color-accent {
  color: var(--darkRed);
  color: var(--color-accent, #f54251);
}
.color-success {
  color: var(--green);
  color: var(--color-success, #88c559);
}
.color-warning {
  color: var(--gold);
  color: var(--color-warning, #ffd138);
}
.color-error {
  color: var(--darkRed);
  color: var(--color-error, #f54251);
}
.width-100\% {
  width: 100%;
}
.height-100\% {
  height: 100%;
}
.media-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}
.media-wrapper iframe,
.media-wrapper video,
.media-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media-wrapper video,
.media-wrapper img {
  object-fit: cover;
}
.media-wrapper--4\:3 {
  padding-bottom: 75%;
}
:root,
[data-theme="default"] {
  --color-primary-darker: hsl(220, 90%, 36%);
  --color-primary-darker-h: 220;
  --color-primary-darker-s: 90%;
  --color-primary-darker-l: 36%;
  --color-primary-dark: hsl(220, 90%, 46%);
  --color-primary-dark-h: 220;
  --color-primary-dark-s: 90%;
  --color-primary-dark-l: 46%;
  --color-primary: hsl(220, 90%, 56%);
  --color-primary-h: 220;
  --color-primary-s: 90%;
  --color-primary-l: 56%;
  --color-primary-light: hsl(220, 90%, 66%);
  --color-primary-light-h: 220;
  --color-primary-light-s: 90%;
  --color-primary-light-l: 66%;
  --color-primary-lighter: hsl(220, 90%, 76%);
  --color-primary-lighter-h: 220;
  --color-primary-lighter-s: 90%;
  --color-primary-lighter-l: 76%;
  --color-accent-darker: hsl(355, 90%, 41%);
  --color-accent-darker-h: 355;
  --color-accent-darker-s: 90%;
  --color-accent-darker-l: 41%;
  --color-accent-dark: hsl(355, 90%, 51%);
  --color-accent-dark-h: 355;
  --color-accent-dark-s: 90%;
  --color-accent-dark-l: 51%;
  --color-accent: hsl(355, 90%, 61%);
  --color-accent-h: 355;
  --color-accent-s: 90%;
  --color-accent-l: 61%;
  --color-accent-light: hsl(355, 90%, 71%);
  --color-accent-light-h: 355;
  --color-accent-light-s: 90%;
  --color-accent-light-l: 71%;
  --color-accent-lighter: hsl(355, 90%, 81%);
  --color-accent-lighter-h: 355;
  --color-accent-lighter-s: 90%;
  --color-accent-lighter-l: 81%;
  --color-black: hsl(240, 8%, 12%);
  --color-black-h: 240;
  --color-black-s: 8%;
  --color-black-l: 12%;
  --color-white: hsl(0, 0%, 100%);
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-success-darker: hsl(94, 48%, 36%);
  --color-success-darker-h: 94;
  --color-success-darker-s: 48%;
  --color-success-darker-l: 36%;
  --color-success-dark: hsl(94, 48%, 46%);
  --color-success-dark-h: 94;
  --color-success-dark-s: 48%;
  --color-success-dark-l: 46%;
  --color-success: hsl(94, 48%, 56%);
  --color-success-h: 94;
  --color-success-s: 48%;
  --color-success-l: 56%;
  --color-success-light: hsl(94, 48%, 66%);
  --color-success-light-h: 94;
  --color-success-light-s: 48%;
  --color-success-light-l: 66%;
  --color-success-lighter: hsl(94, 48%, 76%);
  --color-success-lighter-h: 94;
  --color-success-lighter-s: 48%;
  --color-success-lighter-l: 76%;
  --color-error-darker: hsl(355, 90%, 41%);
  --color-error-darker-h: 355;
  --color-error-darker-s: 90%;
  --color-error-darker-l: 41%;
  --color-error-dark: hsl(355, 90%, 51%);
  --color-error-dark-h: 355;
  --color-error-dark-s: 90%;
  --color-error-dark-l: 51%;
  --color-error: hsl(355, 90%, 61%);
  --color-error-h: 355;
  --color-error-s: 90%;
  --color-error-l: 61%;
  --color-error-light: hsl(355, 90%, 71%);
  --color-error-light-h: 355;
  --color-error-light-s: 90%;
  --color-error-light-l: 71%;
  --color-error-lighter: hsl(355, 90%, 81%);
  --color-error-lighter-h: 355;
  --color-error-lighter-s: 90%;
  --color-error-lighter-l: 81%;
  --color-warning-darker: hsl(46, 100%, 41%);
  --color-warning-darker-h: 46;
  --color-warning-darker-s: 100%;
  --color-warning-darker-l: 41%;
  --color-warning-dark: hsl(46, 100%, 51%);
  --color-warning-dark-h: 46;
  --color-warning-dark-s: 100%;
  --color-warning-dark-l: 51%;
  --color-warning: hsl(46, 100%, 61%);
  --color-warning-h: 46;
  --color-warning-s: 100%;
  --color-warning-l: 61%;
  --color-warning-light: hsl(46, 100%, 71%);
  --color-warning-light-h: 46;
  --color-warning-light-s: 100%;
  --color-warning-light-l: 71%;
  --color-warning-lighter: hsl(46, 100%, 81%);
  --color-warning-lighter-h: 46;
  --color-warning-lighter-s: 100%;
  --color-warning-lighter-l: 81%;
  --color-bg: hsl(0, 0%, 100%);
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-contrast-lower: hsl(0, 0%, 95%);
  --color-contrast-lower-h: 0;
  --color-contrast-lower-s: 0%;
  --color-contrast-lower-l: 95%;
  --color-contrast-low: hsl(240, 1%, 83%);
  --color-contrast-low-h: 240;
  --color-contrast-low-s: 1%;
  --color-contrast-low-l: 83%;
  --color-contrast-medium: hsl(240, 1%, 48%);
  --color-contrast-medium-h: 240;
  --color-contrast-medium-s: 1%;
  --color-contrast-medium-l: 48%;
  --color-contrast-high: hsl(240, 4%, 20%);
  --color-contrast-high-h: 240;
  --color-contrast-high-s: 4%;
  --color-contrast-high-l: 20%;
  --color-contrast-higher: hsl(240, 8%, 12%);
  --color-contrast-higher-h: 240;
  --color-contrast-higher-s: 8%;
  --color-contrast-higher-l: 12%;
}
@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --space-unit: 1.25em;
    }
  }
}
:root {
  --radius: 0.25em;
}
:root {
  --font-primary: sans-serif;
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;
  --text-xs: calc(1em / var(--text-scale-ratio) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(
    var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --body-line-height: 1.4;
  --heading-line-height: 1.2;
  --font-primary-capital-letter: 1;
}
@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --text-base-size: 1.25em;
      --text-scale-ratio: 1.25;
    }
  }
}
mark {
  background-color: hsla(355, 90%, 61%, 0.2);
  background-color: hsla(
    var(--color-accent-h),
    var(--color-accent-s),
    var(--color-accent-l),
    0.2
  );
  color: inherit;
}
.text-component {
  --line-height-multiplier: 1;
  --text-vspace-multiplier: 1;
}
.text-component blockquote {
  padding-left: 1em;
  border-left: 4px solid #d3d3d4;
  border-left: 4px solid var(--color-contrast-low);
}
.text-component hr {
  background: #d3d3d4;
  background: var(--color-contrast-low);
  height: 1px;
}
.text-component figcaption {
  font-size: 0.83333em;
  font-size: var(--text-sm);
  color: #79797c;
  color: var(--color-contrast-medium);
}
.article.text-component {
  --line-height-multiplier: 1.13;
  --text-vspace-multiplier: 1.2;
}
:root {
  --btn-font-size: 1em;
  --btn-font-size-sm: calc(var(--btn-font-size) - 0.2em);
  --btn-font-size-md: calc(var(--btn-font-size) + 0.2em);
  --btn-font-size-lg: calc(var(--btn-font-size) + 0.4em);
  --btn-radius: 0.25em;
  --btn-padding-x: var(--space-sm);
  --btn-padding-y: var(--space-xs);
}
:root {
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0.25em;
}
:root {
  /* --cd-color-1: #333;
  --cd-color-1-h: 206;
  --cd-color-1-s: 21%;
  --cd-color-1-l: 24%;
  --cd-color-2: #a1a1a1;
  --cd-color-2-h: 205;
  --cd-color-2-s: 38%;
  --cd-color-2-l: 89%;
  --cd-color-3: #333;
  --cd-color-3-h: 207;
  --cd-color-3-s: 10%;
  --cd-color-3-l: 55%;
  --cd-color-4: hsl(111, 51%, 60%);
  --cd-color-4-h: 111;
  --cd-color-4-s: 51%;
  --cd-color-4-l: 60%;
  --cd-color-5: #ed1a3b;
  --cd-color-5-h: 356;
  --cd-color-5-s: 53%;
  --cd-color-5-l: 49%;
  --cd-color-6: hsl(47, 85%, 61%);
  --cd-color-6-h: 47;
  --cd-color-6-s: 85%;
  --cd-color-6-l: 61%;*/
  --cd-header-height: 200px;
}
@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --cd-header-height: 300px;
    }
  }
}
.cd-main-header {
  height: 200px;
  height: var(--cd-header-height);
  background: #303f4a;
  background: var(--cd-color-1);
  color: #fff;
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cd-main-header h1 {
  font-family: open sans, sans-serif;
  font-family: var(--font-secondary);
  color: inherit;
}
.cd-timeline {
  overflow: hidden;
  padding: 2em 0;
  padding: var(--space-lg) 0;
  font-family: var(--fontRegular);
  background-color: transparent;
  padding-top: 0;
}
.cd-timeline h2 {
  font-family: open sans, sans-serif;
  font-family: var(--fontBold);
  font-weight: 700;
}
.cd-timeline__container {
  position: relative;
  padding: 1.25em 0;
  padding: 0;
  margin: 5px 0 0 5px;
  max-width: calc(100% - 5px);
}
.cd-timeline__container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: rgba(161, 161, 161, 0.33);
  z-index: 1;
}

@media (min-width: 64rem) {
  .cd-timeline__container::before {
    left: 50%;
    transform: translateX(-50%);
  }
}
.cd-timeline__block {
  display: flex;
  position: relative;
  z-index: 1;
  margin-bottom: 2em;
  margin-bottom: var(--space-lg);
}
.cd-timeline__block:last-child {
  margin-bottom: 0;
}

@media (min-width: 64rem) {
  .cd-timeline__block:nth-child(even) {
    flex-direction: row-reverse;
  }
}
.cd-timeline__img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 4px var(--color-white), inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
  background-color: var(--paleCharcoal_plus30);
  position: relative;
  z-index: 2;
}
.cd-timeline__img img {
  width: 24px;
  height: 24px;
}

@media (min-width: 64rem) {
  .cd-timeline__img {
    width: 60px;
    height: 60px;
    order: 1;
    margin-left: calc(5% - 30px);
    will-change: transform;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__img {
    margin-right: calc(5% - 30px);
  }
}
.cd-timeline__img--picture {
  background-color: var(--green);
}
.cd-timeline__img--movie {
  background-color: var(--ocean);
}
.cd-timeline__img--location {
  background-color: #f0cb47;
  background-color: var(--cd-color-6);
}
.cd-timeline__content {
  flex-grow: 1;
  position: relative;
  margin-left: 1.25em;
  margin-left: var(--space-md);
  background: #fff;
  background: var(--color-white);
  border-radius: 0.25em;
  border-radius: 0;
  padding: 1.25em;
  padding: var(--space-md);
  box-shadow: 0 3px 0 #d8e5ee;
  box-shadow: 0 3px 0 rgba(161, 161, 161, 0.25);
  border-top: 0.5rem solid var(--paleCharcoal_plus30);
  margin-top: -0.5rem;
}
.cd-timeline__content::before {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-right-color: #fff;
  border-right-color: var(--color-white);
}
.cd-timeline__content h2 {
  color: var(--charcoal);
}

@media (min-width: 64rem) {
  .cd-timeline__content {
    width: 45%;
    flex-grow: 0;
    will-change: transform;
    margin: 0;
    --line-height-multiplier: 1.2;
  }
  .cd-timeline__content::before {
    top: 24px;
  }
  .cd-timeline__block:nth-child(odd) .cd-timeline__content::before {
    right: auto;
    left: 100%;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-left-color: #fff;
    border-left-color: var(--color-white);
  }
}
.cd-timeline__date {
  color: var(--paleCharcoal_plus30);
}

@media (min-width: 64rem) {
  .cd-timeline__date {
    position: absolute;
    width: 100%;
    left: 120%;
    top: 20px;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__date {
    left: auto;
    right: 120%;
    text-align: right;
  }
}

@media (min-width: 64rem) {
  .cd-timeline__img--hidden,
  .cd-timeline__content--hidden {
    visibility: hidden;
  }
  .cd-timeline__img--bounce-in {
    animation: cd-bounce-1 0.6s;
  }
  .cd-timeline__content--bounce-in {
    animation: cd-bounce-2 0.6s;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__content--bounce-in {
    animation-name: cd-bounce-2-inverse;
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Account Page */

.myAccount {
  background-color: transparent;
  padding: 0;
}
.taxYearProgress > section {
  background-color: #f8f8f8;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 2.5rem;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
}

.taxYearProgress .icon {
  content: "";
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: var(--paleCharcoal);
  border-radius: 100%;
  margin-right: 0.75rem;
  border: 2px solid white;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
}

.taxYearProgress .complete .icon {
  background-image: url(../icons/ticked.svg);
}
.taxYearProgress .inProgress .icon {
  background-color: transparent;
  border-color: var(--ocean);
}
.taxYearProgress .stage:after {
  font-family: var(--fontBold);
  display: block;
}
.complete .stage:after {
  content: "Complete";
}
.notStarted .stage:after {
  content: "";
}
h4 + .sv_row .sv_p_container,
.small + .sv_row .sv_p_container {
  border-top: none;
  margin-top: 1rem;
}
.processDetail {
  flex: 1 1;
}
.status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 700;
  padding: 1rem 0;
}
.status a {
  color: var(--charcoal);
  text-decoration: none;
}
.cd-timeline .status:before {
  margin-right: 1.5rem;
  min-width: 60px;
}
/* Account/Tax Return Timeline: Updated styles */

.taxYearProgress .icon {
  margin-right: 1.25rem;
}
.taxYearProgress .complete .icon {
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
}
.taxYearProgress .inProgress .icon {
  position: relative;
}
.taxYearProgress .inProgress .icon:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 1.25rem solid transparent;
  border-bottom: 1.25rem solid transparent;
  border-right: 1.25rem solid #f8f8f8;
  left: 50%;
}

@media only screen and (min-width: 813px) {
  /* .taxYearProgress {
            background-color: var(--paleCharcoal_30);
            padding: 1.5rem 0;
        }
        .taxYearProgress:after,
        .taxYearProgress:before {
          display: block;
          content: "";
          width: 50vw;
          height: 100vh;
          background-color: var(--paleCharcoal_30);
          top: 0;
          position: absolute;
          z-index: -1;
        }
        .taxYearProgress:after {
          right: 0;
        }
        .taxYearProgress:before {
          left: 0;
        } */
  /* .taxYearProgress > section {
          padding: 1rem 1.333rem;
        }
        .taxYearProgress .icon {
          width: 2.5rem;
          height: 2.5rem;
        } */
}
.myAccount .pageContent {
  padding: 0;
}
/* progress states */
.notStarted .status {
  display: none;
}
.cd-timeline__img {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 65%;
}
.notStarted .cd-timeline__img {
  background-color: var(--paleCharcoal);
}
.notStarted h2 {
  font-size: 1em;
  font-family: var(--fontRegular);
}
.notStarted .cd-timeline__content {
  opacity: 0.4;
}
.inProgress .cd-timeline__img {
  background-color: var(--slate);
  background-image: url("../icons/timelineSpinner.svg");
}
.inProgress .cd-timeline__content {
  border-color: var(--slate);
}
.complete .cd-timeline__img {
  background-color: var(--ocean);
  background-image: url("../icons/verified.svg");
}
.complete .cd-timeline__content {
  border-color: var(--ocean);
}
.complete:last-child .cd-timeline__img {
  background-color: var(--green);
}
.complete:last-child .cd-timeline__content {
  border-color: var(--green);
}
/* BDO bespoke */
.whatsHappening {
  margin-bottom: 1rem;
}
.notStarted .whatsHappening {
  display: none;
}
@media only screen and (min-width: 813px) {
  .page > header h2 {
    margin: 0 0 1.5rem;
  }
  .cd-timeline__container {
    margin: 5px 0 0;
    max-width: 100%;
  }
}
@media only screen and (max-width: 812px) {
  .taxYearProgress {
    padding: 0 1.5rem 1.5rem;
  }
  .myAccount {
    background-color: var(--paleCharcoal);
  }
}
@media only screen and (min-width: 768px) {
  .cd-timeline__container {
    margin: 5px 0 0 5px;
  }
}

/* Tax year - Small screen styles */
@media only screen and (max-width: 480px) {
  .page.myAccount .card {
    border: none;
  }
  .myTaxReturns h2 {
    font-size: 1.1rem;
  }
  .taxYearHeader > h2 {
    font-size: 2rem;
  }
  .taxYearProgress .notStarted .cd-timeline__content h2 {
    font-size: 1em;
} 
  .fileDeck,
  .buttonDeck {
    flex-direction: row;
    margin: 0;
    width: 100%;
  }
  .taxFileType.pdf,
  .taxFileType.file,
  .taxFileType.folder,
  .taxFileType.fileNotFound,
  .cd-timeline .status:before {
    width: 60px;
    height: 64px;
    padding: 0;
  }
  .taxContainer_annual {
    padding: 1rem 0;
    margin: 0;
    border-radius: 0 !important;
  }
  .taxReview .fileDeck {
    padding: 0.5rem 0 0;
    margin: 0 -0.5rem;
    width: calc(100% + 1rem);
  }
  .taxFileDescription {
      font-size:12px;
      margin-top:1em;
  }
  .taxYearProgress .cd-timeline__content h2 {
    font-size: 1.1rem;
  }
  .taxYearProgress .status {
    padding: 0.5rem 0 0;
  }
  .taxYearProgress .button {
    font-size: 14px;
  }
  .cd-timeline__block {
    font-size: 14px;
  }
}
