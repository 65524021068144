npm/* MSC page */

.marketingPage {
  height: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  background-image: url(../images/homepageBG_1080.jpg);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  background-position: center;
}
.productTaskbar.MSC {
  position: static;
}
.productTaskbar.MSC .mastheadLink {
  color: var(--charcoal);
  font-family: var(--fontRegular);
  font-size: 14px;
  display: flex;
  transition: 0.3s;
  background-color: transparent;
  border: none;
  border-left: 1px solid var(--paleCharcoal);
  flex: 1 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  white-space: nowrap;
}
.productTaskbar.MSC .mastheadLink:last-child {
  border-right: 1px solid var(--paleCharcoal);
}
.productTaskbar.MSC .mastheadLink:hover {
  text-decoration: none;
  color: var(--slate);
}
.productTaskbar.MSC div section {
  display: flex;
  align-items: center;
}
.productTaskbar.MSC .branding {
  margin-left: 0;
}
.productTaskbar.MSC .globalNav {
  /* border-left: 1px solid var(--paleCharcoal); */
  /* width: auto !important; */
  padding-left: 3rem;
}
.marketingPage article {
  overflow-x: hidden;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.marketingPage article img {
  display: none;
}
.CTA_strap {
  flex: 1 1;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.CTA_strap .button.getStarted:hover {
  background-color: var(--slate);
  border-color: var(--slate);
}
.responsivePanel,
.productTaskbar.MSC .globalNav {
  max-width: 100%;
  min-width: calc(300px + 3.5rem);
  width: calc(var(--fixedWidth) / 4);
  /* width: calc(300px + 7rem); */
}
.responsivePanel {
  color: white;
  padding: 0 0 1rem 3.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: var(--fontRegHomepage);
  height: calc(100vh - var(--masthead));
}
.responsivePanel:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  min-width: 1080px;
  width: 1080px;
  height: 100%;
  background: var(--slate);
  left: 100%;
}
.responsivePanel,
.responsivePanel:before {
  background: rgba(51, 51, 51, 0.8);
  background: rgb(36 34 47 / 0.8);
  /* background: var(--charcoal); */
}
.responsivePanel h2,
.responsivePanel h3 {
  font-family: var(--fontBoldHomepage);
  margin: 0;
}

.fixedWidthWrapper {
  max-width: var(--fixedWidth);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1;
  justify-content: space-between;
  padding: 0 3rem;
}
.marketingPage article > .fixedWidthWrapper {
  flex-direction: row-reverse;
}
.marketingPage .options {
  display: flex;
  justify-content: flex-end;
}
.CTA_subStrap {
  font-size: 14px;
  padding: 2rem 0 4rem;
}

.responsivePanel h3 {
  font-weight: 400;
  font-size: 14px;
  color: var(--paleCharcoal);
  width: 100%;
}
.responsivePanel p {
  margin: 0;
  line-height: 1em;
  margin-bottom: 1em;
}
.responsivePanel p:last-child {
  margin-bottom: 0;
}
.responsivePanel h3 p {
  margin-bottom: 0.4em;
  line-height: 1.3em;
}
.CTA_strap h2 {
  font-size: 2.1rem;
  margin-bottom: 0;
  letter-spacing: 0.1em;
}

.productTaskbar.MSC .button.primary {
  width: auto;
  padding: 0 1.5rem;
  margin: 0 0 0 1rem;
  /* width: calc(33.333% - 1.5rem); */
  min-width: 5.5rem;
  font-size: 14px;
  display: none;
}
.productTaskbar.MSC .button.primary strong {
  margin: 0 0.25em;
}
.marketingPage .button.getStarted {
  width: 100%;
  max-width: 100%;
}

.marketingPage .globalFooter .desktopWrapper {
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 0 calc(16px + (24px * 2));
}
.marketingPage .globalFooter {
  padding: 0;
  background: transparent;
}
.marketingPage .globalFooter a,
.marketingPage .globalFooter span {
  color: var(--paleCharcoal);
  text-transform: none;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}
.marketingPage .globalFooter .bdoInformation a:before {
  color: var(--paleCharcoal);
}
.marketingPage .socialMedia {
  width: 100%;
  justify-content: flex-start;
  padding-bottom: 1rem;
  opacity: 0.8;
}
.marketingPage .socialItem {
  width: 13%;
  height: 0;
  padding-bottom: 13%;
  display: flex;
  margin-left: 0.5rem;
  position: relative;
}
.marketingPage .socialItem:first-child {
  margin-left: 0;
}
.marketingPage .socialItem > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  margin: 0;
  background: transparent;
}

.registeredUser {
  font-family: var(--fontRegular);
  font-size: 14px;
  text-transform: none;
  margin: 1.5rem 0 0 !important;
  color: var(--paleCharcoal);
  line-height: 1.4em !important;
}
.registeredUser > a {
  color: white;
}

/* New stuff */
.marketingPage.altDesign {
  background-image: url(../images/homepageBG_1080.jpg);
}
.altDesign article > .fixedWidthWrapper {
  flex-direction: row;
}
.marketing {
  flex: 1 1;
  display: flex;
  color: white;
  flex-direction: column;
  overflow: hidden;
  padding-right: 2.5rem;
}

.altDesign .CTA_subStrap {
  font-family: var(--fontLightHomepage);
  padding: 0.5rem 0 0;
  font-size: 1.5rem;
  text-transform: none;
  margin-bottom: calc(1.5rem + 11px);
  font-weight: 400;
  display: flex;
  align-items: flex-start;
}
.altDesign .CTA_subStrap > span:before {
  display: inline-flex;
  border-left: 1px solid white;
  content: "";
  height: 18px;
  margin: 0 0.5em;
}
.altDesign .CTA_subStrap > span:first-child:before {
  display: none;
}
.altDesign .CTA_strap h1 {
  display: block;
  font-size: 3.5rem;
  letter-spacing: 0;
  line-height: 40px;
  color: white;
  font-family: var(--fontHeavyHomepage);
  margin-top: calc(2rem + 11px);
}
.altDesign .CTA_strap aside {
  border: 2px solid #666;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 1.5rem;
}

.altDesign .CTA_strap aside h3 {
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  color: white;
}

.altDesign .CTA_strap aside p {
  font-size: 0.875rem;
  text-transform: none;
  font-family: var(--fontRegular);
  line-height: 1.5em;
}

.altDesign .globalFooter {
  max-width: calc(var(--fixedWidth) / 4);
}

.altDesign .CTA_strap .button {
  max-width: 100%;
  font-family: var(--fontBoldHomepage);
  font-size: 1rem;
  margin: 1rem 0;
}
.altDesign .CTA_strap .warning {
  margin: 1rem 0 1rem 2.5em;
}
.altDesign .CTA_strap p a {
  color: white;
  text-decoration: underline;
}
.altDesign .CTA_strap .warning:before {
  content: "!";
  display: inline-flex;
  height: 1.5em;
  width: 1.5em;
  border: 2px solid var(--paleCharcoal);
  float: left;
  margin: 0.15em 0 0 -2.5em;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: var(--slate);
}
.warning strong {
  display: block;
  font-family: var(--fontBold);
}

.altDesign .CTA_strap h2 {
  font-family: var(--fontRegHomepage);
  line-height: 1.3em;
  font-size: 1.25rem;
  text-transform: none;
  margin: 1.5rem 0;
}
.clientOptions {
  display: flex;
  margin: 0 -0.5rem;
}

.clientOptions .button {
  border-width: 2px;
  border-radius: 0 !important;
  font-family: var(--fontHomeLight);
  margin: 0 0.5rem;
}
.locator {
  width: 16px;
  margin-left: 1.5rem;
  background-color: var(--red);
  display: flex;
  flex: 1 1;
  position: relative;
}
.locator.locTop {
  margin-top: -15vh;
}
.locator.locBottom {
  margin-bottom: -20%;
}
.locBottom:before,
.locTop:after {
  content: "";
  width: 0px;
  height: 0px;
}
.locBottom:before {
  border-left: 16px solid transparent;
  border-bottom: 11px solid var(--red);
  margin-top: -11px;
}
.locTop:after {
  border-right: 16px solid transparent;
  border-top: 11px solid var(--red);
  position: absolute;
  bottom: -11px;
}
.existingClient .button.primary {
  background-color: var(--red);
  border-color: var(--red);
}
.existingClient .button.primary:hover {
  background-color: var(--slate);
  border-color: var(--slate);
}
.globalFooter.smallScreenFooter {
  display: none;
}

@media screen and (min-width: 1440px) {
  html {
    /*font-size: calc(1em + 2 * ((100vw - 375px) / 680));*/
  }
}
@media screen and (max-width: 1210px) {
  .altDesign .CTA_strap h1 {
    line-height: 1em;
  }
  .altDesign .CTA_strap h1 > span {
    display: block;
  }
  .altDesign .CTA_subStrap {
    flex-direction: column;
    font-size: 1.15rem;
    font-family: var(--fontBoldHomepage);
    padding-top: 1rem;
    line-height: 1.5em;
  }
  .altDesign .CTA_subStrap > span {
    display: flex;
    align-items: center;
  }
  .altDesign .CTA_subStrap > span:before {
    content: none;
  }
}

@media (max-aspect-ratio: 3/3) {
  
  .marketingPage {
    background-size: cover;
    background-position: 33.333% 50%;
  }
  .productTaskbar.MSC {
    /* height: calc(var(--masthead) * 2); */
    height: auto;
  }
  .altDesign .CTA_strap h1 > span {
    display: inline;
  }
  .altDesign .CTA_subStrap {
    font-family: var(--fontLightHomepage);
    padding: 0.5rem 0 0;
    font-size: 1.5rem;
    text-transform: none;
    margin-bottom: calc(1.5rem + 11px);
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .altDesign .CTA_subStrap > span:before {
    content: "";
  }
  .productTaskbar.MSC .fixedWidthWrapper {
    flex-direction: column;
    justify-content: center;
  }
  .fixedWidthWrapper {
    padding: 0;
  }
  .altDesign article > .fixedWidthWrapper {
    flex-direction: column;
  }
  .productTaskbar.MSC .fixedWidthWrapper > section {
    height: var(--masthead);
    width: 100%;
    min-width: 100%;
  }
  .CTA_strap {
    justify-content: flex-start;
  }
  .flexbox {
    display: flex;
    margin: 0;
  }
  .legalPrivacy .flexbox {
    margin: 0;
  }
  .legalPrivacy .flexbox > a {
    margin-right: 0.75em;
  }
  .legalPrivacy .flexbox > a:before {
    content: none;
  }
  .legalPrivacy .flexbox > a:first-child:after {
    content: "|";
    margin-left: 0.75em;
  }
  .altDesign .CTA_strap aside {
    margin: 0;
  }
  .altDesign .CTA_strap aside:first-child {
    margin-right: 1.5rem;
  }
  .productTaskbar.MSC .fixedWidthWrapper .globalNav {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid var(--paleCharcoal);
    border-left: none;
    height: 3rem;
    padding: 0;
  }

  .productTaskbar.MSC .button.primary {
    max-height: 1.5rem;
    padding: 0;
    width: calc(33.333% - 2rem);
    margin: 0 1rem;
    height: 1rem;
    max-height: 1rem;
    line-height: 1rem;
    min-height: 1.9rem;
    border-radius: 0 !important;
  }
  .productTaskbar.MSC .branding {
    background: white;
    margin: 0;
    padding-left: 1.5rem;
  }
  .responsivePanel {
    padding: 0 1.5rem 1.5rem;
    min-width: 100%;
    width: 100%;
    height: auto;
    flex: 0.7;
  }
  .marketing {
    padding: 0;
    flex: 1;
    min-height: 33.333vh;
  }
  .marketing .CTA_strap {
    padding: 0 1.5rem;
    min-height: fit-content;
    width: 100%;
  }
  .CTA_strap h2 {
    font-size: 1.9rem;
    margin-bottom: 2rem;
  }
  .CTA_strap h2 p {
    line-height: 1em;
  }
  .responsivePanel p:last-child {
    margin-bottom: 0;
  }
  .marketingPage .socialMedia {
    max-width: 320px;
    justify-content: flex-end;
  }
  .productTaskbar.MSC .fixedWidthWrapper .globalNav a,
  .productTaskbar.MSC .fixedWidthWrapper .globalNav button {
    font-size: 14px;
  }
  .marketing > .globalFooter {
    display: none;
  }
  .globalFooter.smallScreenFooter {
    background: var(--charcoal);
    max-width: 100%;
    display: flex;
  }

  .globalFooter.smallScreenFooter > .desktopWrapper {
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0.5rem;
  }
}

@media screen and (max-width: 800px) {
.altDesign .CTA_subStrap {
  flex-direction: column;
  font-size: 0.875rem;
  font-family: var(--fontBoldHomepage);
  padding-top: 1rem;
  line-height: 1.5em;
  align-items: flex-start;
}
.altDesign .CTA_subStrap > span:before {
  content: none;
}
}



@media (max-aspect-ratio: 2/3) {
  .productTaskbar.MSC .mastheadLink {
    display: none;
  }
  .productTaskbar.MSC .mastheadLink.emphasise {
    display: flex;
  }
  /*.productTaskbar.MSC .mastheadLink.emphasise {
    display: flex;
    border: none;
    background: var(--slate);
    min-width: calc(50% - 1rem);
    flex: 0.4;
    color: white;
    justify-content: center;
    padding: 0;
    border-radius: 5px;
  }
  .productTaskbar.MSC .mastheadLink.enquiry {
    background: var(--ocean)
  }
  .productTaskbar.MSC .mastheadLink.signIn {
    background: var(--red)
  }*/
  .marketing {
    min-height: 50vh;
  }
  .altDesign .CTA_strap h1 {
    font-size: 2.5rem;
    line-height: 1.1em;
  }
  .altDesign .CTA_subStrap {
    flex-direction: column;
    font-size: 0.875rem;
    font-family: var(--fontBoldHomepage);
    padding-top: 1rem;
    line-height: 1.5em;
    align-items: flex-start;
  }
  .altDesign .CTA_subStrap > span:before {
    content: none;
  }
  .altDesign .CTA_strap h2 {
    font-size: 1.25rem;
    margin: 1rem 0;
  }
  .flexbox {
    flex-direction: column;
    margin: 0;
  }
  .altDesign .CTA_strap aside {
    margin: 0;
    padding: 1rem 0 0.5rem;
    border: none;
    border-top: 1px solid rgb(255 255 255 / 0.15);
  }
  .globalFooter.smallScreenFooter > .desktopWrapper {
    flex-direction: column-reverse;
  }
  .marketingPage .socialMedia {
    justify-content: flex-start;
    margin-left: 0;
  }
}
@media only screen and (max-width: 400px) {
  .responsivePanel {
    padding: 0 1.5rem;
    flex: 0.5;
  }
  .marketing {
    min-height: 55vh;
  }
  .altDesign .CTA_strap h2,.altDesign .CTA_strap aside h3 {
  font-size: 1rem;
  }
}


/* --- End of home page media queries --- */

/* Registration Questionnaire */

  .registrationPage .contentWrapper {
    padding-top: 5.25rem;
  }
  @media only screen and (max-width: 1080px) {
    .registrationPage .contentWrapper {
    padding-top: calc(var(--masthead) + 4rem);
    }
  }
.registrationPage .panel-body > div > h4, .registrationPage .sv_page_title {
  display:none;
}

.mirrorOptions {
    display: flex;
    justify-content: space-between;
}
.mirrorOptions>section {
    color: white;
    background: var(--slate);
    border-top: 0.5rem solid #546879;
    width: calc(50% - 1rem);
    padding: 1rem 1.5rem;
    font-size: 0.875rem;
}
.mirrorOptions>section {
  background: var(--ocean);
  border-color: var(--ocean_plus20);
}
.mirrorOptions h2 {
    font-size: 1.15rem;
    margin: 0 0 1rem;
    padding: 0 0 1rem;
    text-align: left;
    line-height: 1em;
    background-color: transparent;
    display: block;
    height: auto;
    text-align: left;
    border-bottom: 1px solid rgb(238 255 255 / 0.3);
    letter-spacing: 0.015em;
    font-family: var(--fontBold);
}
.registrationPage .productTaskbar.MSC {
  position: fixed;
}
.registrationPage .sv_main:before {
  top: 0;
  display: none;
}

.registrationPage .sv_row > .sv_p_container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.registrationPage .page.survey {
  padding: 0;
  margin-top: 0;
}
.registrationPage .sv_main > form {
  padding-top: 0;
}
/* intro banner */
.introBanner .sv_p_title {
  margin-top: 0.25rem !important;
  margin-bottom: 1rem !important;
}
.sv_row div[name="introductionBanner"] {
  background: var(--slate);
  color: white;
  padding: 1.5rem !important;
  margin-top: 2rem;
  border-top: 0.5rem solid #516475;
}
.sv_row div[name="introductionBanner"] h2,
.sv_row div[name="introductionBanner"] h3 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem;
}
.sv_row div[name="introductionBanner"] h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
.registrationPage .successPage {
  text-align: left;
  position: static;
  padding-top: 1.5rem;
  height: 100%;
  flex: 1 1;
  margin-bottom: -2rem;
  padding-bottom: 2rem;
}
.registrationPage .successPage p a {
  display: inline;
  color: white;
  text-decoration: underline;
}
.registrationPage .successMessage {
  align-items: flex-start;
  max-width: 400px;
}
.registrationPage .successMessage .button {
  margin-top: 1rem;
  font-size: 1rem;
  border-width: 2px;
  border-radius: 0 !important;
}
.registrationPage .successPage h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.registrationPage .successPage p {
  font-size: 16px;
  width: 100%;
}
.registrationPage .asterisk {
  font-family: "consolas", sans-serif;
  font-size: 1.1rem;
}
.registrationPage footer i .asterisk {
  margin-right: 0.25em;
}
.registrationPage
  #sp_112
  .sv_row
  > .sv_p_container
  .sv_row
  > .sv_p_container
  .sv_row:first-child
  .sv_qstn
  fieldset
  .checkbox {
  margin: 0;
}
.registrationPage
  #sp_112
  .sv_row
  > .sv_p_container
  .sv_row
  > .sv_p_container
  .sv_row:first-child
  .sv_qstn
  label {
  font-size: 1rem;
  font-family: var(--fontBold);
  text-align: left;
}
.registrationPage
  #sp_112
  .sv_row
  > .sv_p_container
  .sv_row
  > .sv_p_container
  .sv_row
  + .sv_row {
  border: none;
  margin: 0;
  padding-top: 0;
  padding-left: 2rem;
}
/* Registration page media queries */
@media only screen and (max-width: 800px) {
  .sv_row div[name="introductionBanner"] {
    padding: 0 1.5rem !important;
  }
  .registrationPage .successPage p {
    font-size: 14px;
  }
  .sv_row .sv_qstn[name="mirrorOptions"] {
    padding: 0 !important;
}
  .mirrorOptions {
    flex-direction: column;
  }
  .registrationPage .contentWrapper {
    padding-top: calc(var(--masthead) + 3rem);
  }
  .mirrorOptions>section {margin:0;width:100%;border-top-width:3px;font-size:14px;}
  .mirrorOptions>section+section {
    border-top: none;
    padding-top:0;
  }
  .mirrorOptions>section+section h2 {
    border-top: 1px solid rgb(255 255 255 / 0.15);padding:1rem 0;
  }
  .mirrorOptions h2 {
    margin: 0;
    border-bottom: none;
  }
}
@media only screen and (max-width: 600px) {
  .registrationPage .contentWrapper {
    padding-top: calc(var(--masthead) + 3rem);
  }
  .registrationPage .panel-body > div > h4 {
    height: 3rem;
    font-size: 14px;
    text-align: left;
    justify-content: flex-start;
    padding: 0 1.5rem;
    margin: 0;
    font-family: var(--fontRegular);
  }

  .sv_row div[name="introductionBanner"] h2 {
    font-size: 1.3rem;
  }
  .sv_row div[name="introductionBanner"] h3 {
    font-size: 1rem;
  }
  .sv_row div[name="introductionBanner"] {
    padding: 1.5rem !important;
    margin-top: 0;
    border-top: none;
  }
  .registrationPage .sv_row div[style*="width: 33.3333%"],
  .registrationPage .sv_row div[style*="width: 50%"] {
    padding-top: 1rem !important;
  }
  .registrationPage .successPage h2 {
    font-size: 2rem;
  }

  
}
