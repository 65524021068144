* {
	box-sizing: border-box;
}
@font-face {
	font-family: "Proxima Nova Regular";
	src: url(fonts/OTF/ProximaNovaReg.otf) format("opentype");
}
@font-face {
	font-family: "Proxima Nova Bold";
	src: url(fonts/OTF/ProximaNovaBold.otf) format("opentype");
}
@font-face {
	font-family: "Bliss Regular";
	src: url(fonts/OTF/BlissRegular.otf) format("opentype");
}
@font-face {
	font-family: "Bliss Bold";
	src: url(fonts/OTF/BlissBold.otf) format("opentype");
}
@font-face {
	font-family: "Bliss Heavy";
	src: url(fonts/OTF/BlissHeavy.otf) format("opentype");
}
@font-face {
	font-family: "Bliss Light";
	src: url(fonts/OTF/BlissLight.otf) format("opentype");
}
*:focus {
	outline: none !important;
}
:root {
	--charcoal: #333333;
	--charcoal_50: #999999;
	--ocean: #008fd2;
	--ocean_20: #33A5DB;
	--ocean_plus20: #0072A8;
	--ocean_plus30: #006493;
	--slate: #5b6e7f;
	--gold: #d67900;
	--orange: #ff5722;
	--green: #4caf50;
	--red: #ed1a3b;
	--darkRed: #b52138;
	--burgundy: #98002e;
	--paleCharcoal: #e7e7e7;
	--paleCharcoal_30: #f8f8f8;
	--paleCharcoal_plus30: #a1a1a1;
	--borderLight: 1px solid #e7e7e7;
	--borderLight: 1px solid var(--paleCharcoal);
	--borderDark: 1px solid #a1a1a1;
	--borderDark: 5px solid var(--paleCharcoal_plus30);
	--masthead: 70px;
	--navbar: 80px;
	--progressbar: 0.5rem;
	--surveyHeader: 120px;
	--surveyNavColumn: calc(25% - 1.5rem);
	--fixedWidth: 960px;
	--fixedWidth: 1366px;
	--fontRegular: "Proxima Nova Regular", sans-serif;
	--fontBold: "Proxima Nova Bold", sans-serif;
	--fontRegHomepage: "Bliss Regular", sans-serif;
	--fontBoldHomepage: "Bliss Bold", sans-serif;
	--fontLightHomepage: "Bliss Light", sans-serif;
	--fontHeavyHomepage: "Bliss Heavy", sans-serif;
	--oldiPhone: 320px;
}
:root .marketingPage {
	--fixedWidth: 85.375rem;
}
:root .registrationPage {
	--fixedWidth: 1366px;
}
@media only screen and (max-width: 480px) {
	:root {
		--navbar: 4rem;
	}
}
html {
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
}
body {
	margin: 0;
	position: relative;
	font-family: var(--fontRegular);
	font-size: 16px;
	line-height: 1.4em;
	-webkit-font-smoothing: subpixel-antialiased;
	background-color: white;
	color: var(--charcoal);
	overflow-y: scroll;
	overscroll-behavior: none;
}
/* Generic elements */

input,
select {
	font-size: 100%;
}
h1,
h2,
h3,
h4,
h5 {
	line-height: 1.4em;
	margin-top: 0;
	font-size: 1rem;
}
h1,
h2 {
	font-family: var(--fontBold);
}
strong,
b {
	font-weight: 600;
}
p {
	margin: 0 0 1em;
}
p:last-child {
	margin-bottom: 0;
}
a,
a:link,
a:visited {
	text-decoration: none;
	transition: 0.5s;
}
a:hover,
a:active,
.globalFooter a:not([href]):not([tabindex]):hover {
	text-decoration: underline;
}
p > a {
	color: var(--ocean);
	font-family: var(--fontBold);
}
dl,
ol,
ul {
	padding: 0;
	margin-bottom: 1.5rem;
	list-style-type: none;
}
li {
	padding-bottom: 0.75rem;
	position: relative;
	margin-left: 1.25rem;
	margin-bottom: 0.333rem;
}
li:before {
	content: "";
	position: absolute;
	left: -1.25rem;
	width: 0;
	height: 0;
	border-top: 0.333rem solid transparent;
	border-bottom: 0.333rem solid transparent;
	border-left: 0.5rem solid var(--charcoal);
	margin-top: 0.45em;
}
form {
	margin: 0;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
select.form-control::-ms-expand {
	display: none;
}
input::-ms-clear {
	display: none;
}
img,
video,
svg {
	max-width: 100%;
}
/* Full-screen Containers */

html,
body {
	height: 100%;
	width: 100%;
}
html,
body,
#root,
.App,
.sectionContent,
.sv_main {
	min-height: 100%;
	min-width: 100%;
}
.App {
	min-height: 100vh;
	min-width: var(--oldiPhone);
	overflow-x: hidden;
}
.App,
.sv_main > form {
	display: flex;
	flex-direction: column;
}
.sv_main > form {
	height: 100%;
}
.contentWrapper {
	display: flex;
	flex: 1;
	padding-top: var(--masthead);
	padding-bottom: 2rem;
}
.sectionContent {
	flex: 1;
}
/* shared max-width elements */

.desktopWrapper,
.contentWrapper {
	margin: 0 auto;
	width: 100%;
	max-width: calc(var(--fixedWidth) - 3rem);
	margin: 0 auto;
}
/* Taskbar / Masthead */

.productTaskbar {
	width: 100%;
	background-color: white;
	color: var(--ocean);
	position: fixed;
	z-index: 1001;
	top: 0;
	left: 0;
	right: 0;
	background-color: white;
	/*border-bottom: 1px solid var(--paleCharcoal);*/
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.productTaskbar,
.productTaskbar .desktopWrapper {
	height: var(--masthead);
}
.desktopWrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	justify-content: space-between;
	padding: 0;
}
.branding > a {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
	text-decoration: none;
}
.branding h2,
h1 {
	font-family: var(--fontBold);
	margin: 0;
}
.branding h2 {
	font-size: 13px;
	width: 65px;
	line-height: 1.16em;
	color: var(--red);
	margin-left: 0.5rem;
	flex: 0 1;
}
h1 {
	display: block;
	margin: 0;
	color: var(--charcoal);
	font-size: 1.25rem;
}
#bdoLogo {
	height: 36px;
	width: 95px;
}
.branding h2,
h1 {
	display: none;
}
.homepage {
	height: var(--masthead);
}
.accountPage .accountPage {
	height: var(--masthead);
	display: flex;
	align-items: center;
	font-size: 16px;
}
.textTitle {
	font-size: 0;
}
a.accountPage {
	color: var(--charcoal);
}
a.accountPage:hover {
	text-decoration: none;
	color: var(--ocean);
}
.accountPage svg {
	margin-left: 0.5em;
}
.accountPage:hover svg path {
	fill: var(--ocean);
}
.accountPage,
.accountPage svg path {
	transition: 0.3s;
}
/* Global footer */

.globalFooter {
	padding-right:calc(160px + 3.5rem);
	padding-left:calc(160px + 3.5rem);
	font-size: 14px;
	min-height: var(--navbar);
	display: flex;
	padding-bottom: calc(var(--navbar) + 0.5rem);
	padding-top: 1.5rem;
	padding-top: 0;
}
.globalFooter.medicalFooter{
	padding-right:0px;
	padding-left:calc(200px + 3.5rem);
}
.globalFooter .desktopWrapper {
	display: flex;
	flex-direction: row;
}
.globalFooter section {
	padding-bottom: 1rem;
}
.legalPrivacy {
	display: flex;
	flex-direction: column;
}
.socialIcon {
	display: block;
	width: 2.5rem;
	height: 2.5rem;
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	transition: 0.3s;
	opacity: 0.75;
	border: 2px solid #e7e7e7;
	border-radius: 6px;
}
.socialIcon:hover {
	opacity: 1;
}
.socialIcon.facebook {
	background-image: url(icons/facebook.svg);
}
.socialIcon.twitter {
	background-image: url(icons/x-twitter.svg);
}
.socialIcon.linkedin {
	background-image: url(icons/linkedin.svg);
}
.socialIcon.youtube {
	background-image: url(icons/youtube.svg);
}
.socialIcon.instagram {
	background-image: url(icons/instagram.svg);
}
.socialMedia {
	display: flex;
	justify-content: space-between;
}
.globalFooter a,
.globalFooter a:not([href]):not([tabindex]),
.globalFooter .bdoInformation a {
	color: var(--charcoal);
	font-family: var(--fontRegular);
	font-size: 14px;
	line-height: 1.5em;
}
.globalFooter .bdoInformation a:before {
	content: ">";
	color: var(--charcoal);
	font-family: "consolas";
	text-decoration: none;
	display: inline-block;
	font-weight: 700;
	margin-right: 0.333em;
	font-size: 1.2em;
}

.supportInformation {
	text-align: center;
	font-size: 10px;
	line-height: 1rem;
}

#supportNumber {
	color: var(--ocean);
}

#supportEmail {
	text-decoration: underline; 
	font-size: 10px;
}

.progress {
	display: none;
}

@media only screen and (max-width: 480px) {
	.socialMedia {
		margin-left: -0.5rem;
	}
	.globalFooter .desktopWrapper {
		align-items: flex-start;
		flex-direction: column;
	}
	.globalFooter section {
		padding-bottom: 2rem;
	}
	.globalFooter .bdoInformation {
		padding-bottom: 1.5rem;
	}
}

.queryFooter {
	display: flex;
	justify-content: space-between;
}
/* Page styling */

.page {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.page > header {
	padding: 1.5rem 1.5rem 1rem;
}
.page > header,
.pageContent,
.productTaskbar .desktopWrapper,
.globalFooter .desktopWrapper {
	padding: 0 1.5rem;
}
.pageContent {
	flex: 1;
	padding-top: 1.5rem;
}
.page h2 {
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
}
.page > header h2 {
	margin-top: 1.5rem;
}
.page p {
	line-height: 1.5em;
	margin-bottom: 1em;
}
.page.survey {
	padding-top: var(--progressbar);
}
@media only screen and (max-width: 480px) {
	.page h2 {
		margin-bottom: 0.5rem;
	}
}
/* FAQ modal */

.faqsModal .modal-content {
	max-height: 600px;
}
.faqsModal .modal-body {
	overflow-y: scroll;
	padding: 0 2.5rem;
}
.faqsModal .card {
	border: none;
	border-top: 1px solid var(--paleCharcoal);
	border-radius: 0;
}
.faqsModal .card:first-child {
	border-top: none;
}
.faqsModal .accordion > .card .card-header button {
	font-size: 1rem;
	transition: 0.5s;
	text-decoration: none;
	max-height: none;
}
.faqsModal .card-body ul {
	text-align: left;
	margin: 0;
}
.faqsModal .card-body ul li {
	font-size: 1rem;
}
.faqsModal .modal-title {
	padding: 0;
	line-height: 1em;
	color: var(--slate);
	text-align: left;
}
.faqsModal .modal-header {
	padding: 1.5rem 1.5rem 1.5rem 2.5rem;
}
.faqsModal button.close {
	line-height: 1em;
	padding: 0;
	margin-right: 0;
	color: var(--charcoal);
}
.faqsModal li:before {
	border-left-color: var(--ocean);
}
.faqsModal .accordion > .card .card-header button:hover {
	text-decoration: none;
}
/* plus/minus symbols plus animation */

.faqsModal .card {
	position: relative;
}
.faqsModal .accordion > .card .card-header {
	position: relative;
	z-index: 1;
}
.faqsModal .accordion > .card .card-header button {
	padding-left: 3rem;
}
.faqsModal .collapse:not(.show) {
	display: block;
}
.faqsModal .collapse {
	max-height: 0;
	height: auto;
	overflow: hidden;
	transition: 0.5s;
}
.faqsModal .card .collapse.show {
	max-height: 1000px;
}
.faqsModal .card .collapse:before {
	content: "\002B";
	position: absolute;
	top: 0;
	left: 0;
	height: 2rem;
	width: 2rem;
	background: white;
	font-size: 2rem;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid var(--charcoal);
	color: var(--charcoal);
	padding-bottom: 1px;
	margin-top: 1.15rem;
	z-index: 0;
}
.faqsModal .card .collapse.show:before {
	content: "\2212";
	color: var(--ocean);
	border-color: var(--ocean);
}
.faqsModal .card-header + .collapse:before,
.faqsModal .collapse:before {
	transition: 0.5s;
}
.faqsModal .card-header:hover + .collapse:before,
.faqsModal .card .collapse:hover:before {
	color: var(--ocean);
	border-color: var(--ocean);
	cursor: pointer;
}
@media only screen and (max-width: 480px) {
	.faqsModal .modal-dialog {
		max-height: 100vh;
		margin: 0;
		min-width: 360px;
	}
	.faqsModal .modal-dialog .modal-content {
		border-radius: 0;
		border: none;
		height: 100vh;
		max-height: 100vh;
	}
	.faqsModal .modal-header {
		padding: 1.5rem;
	}
	.faqsModal .modal-body {
		max-height: none;
		padding: 0 1.5rem;
	}
	.faqsModal .accordion > .card .card-body {
		padding-left: 3rem;
	}
	.faqsModal .card-body ul {
		margin-left: -1.25rem;
	}
	.faqsModal .modal-footer {
		border: none;
		height: 0;
		overflow: hidden;
		padding: 0;
	}
	.faqsModal button.close {
		margin: -0.5rem;
	}
}
/* Standard Spinner */

.spinnerModal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	min-height: 100vh;
	min-width: 100%;
	background-color: white;
}
.spinner-border {
	width: 4rem;
	height: 4rem;
	border: 0.25rem solid var(--charcoal);
	border-right-color: transparent;
}

/* Medical Spinner*/
.medicalSpinner{
	flex-direction: row !important;
padding-right: 40%!important;
padding-left: 40%!important;
min-height: auto!important;
}
/* Filter Question Styling */

.filterQuestions > header {
	margin-bottom: 1.5rem;
}
.page.filterQuestions > header h3 {
	margin-bottom: 0.5em;
}
.tileGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
	grid-auto-rows: minmax(auto, auto);
	grid-gap: 1em;
	align-items: stretch;
}
.tile {
	width: 100%;
	position: relative;
	margin: 0;
	display: flex;
}
.tile:before {
	content: "";
	display: inline-block;
	width: 1px;
	height: 0;
	padding-bottom: 100%;
}
.tile .activeArea {
	border-radius: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	line-height: 1.4em;
	font-weight: 400;
	font-size: 1rem;
	padding: 2.5rem 1rem;
	transition: 0.3s;
	width: 100%;
	color: var(--charcoal);
	background-color: var(--paleCharcoal_30);
	border: 1px solid rgb(101 124 145 / 20%);
	background-image: linear-gradient(360deg, rgb(51 51 51 / 15%), transparent);
	font-family: var(--fontBold);
}
.tile:hover .activeArea {
	background-color: var(--slate);
	border-color: var(--slate);
	color: white;
	cursor: pointer;
}
.tile.selected .activeArea,
.tile.selected:hover .activeArea {
	background-color: var(--ocean);
	border-color: var(--ocean);
	color: white;
	cursor: pointer;
}
.filterQuestions .close:not(:disabled):not(.disabled):focus,
.filterQuestions .close:not(:disabled):not(.disabled):hover {
	opacity: 1;
	background-color: var(--charcoal);
	transition: 0.3s;
	cursor: pointer;
}
.tile .close {
	position: absolute;
	color: #fff;
	opacity: 1;
	text-shadow: none;
	font-size: 1.5rem;
	width: 2rem;
	height: 2rem;
	border: 2px solid #fff;
	border-radius: 100%;
	right: 0.75rem;
	top: 0.75rem;
}
.prevYear {
	width: auto;
	padding: 0.1em 0.5em;
	height: auto;
	font-size: 12px;
	color: var(--charcoal);
	background-color: var(--ocean);
	background-color: var(--slate);
	background-color: rgb(51 51 51 / 20%);
	font-family: var(--fontRegular);
	letter-spacing: 0.05em;
	position: absolute;
	top: 1px;
	left: 1px;
	border-top-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.selected .prevYear,
[class*="selected"]:hover .prevYear {
	color: white;
}
/* Tiles with image icons */

.tile .activeArea {
	flex-direction: column;
	padding: 0;
	justify-content: flex-start;
}
.tileIcon {
	width: 100%;
	max-height: 33.333%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center bottom;
	margin-top: 25%;
	transition: 0.3s;
	flex: 1;
}
.tile .activeArea:before {
	content: "";
	display: block;
}
.tileTitle {
	line-height: 1.3;
	padding: 0.5rem;
}
/* Charcoal icons */

.tile[name="Interest, dividends and pension income"] .tileIcon {
	background-image: url("icons/tileIcons/interestDividends_charcoal.png");
}
.tile[name="Employment income"] .tileIcon {
	background-image: url("icons/tileIcons/employmentIncome_charcoal.png");
}
.tile[name="Self-employment income"] .tileIcon {
	background-image: url("icons/tileIcons/selfEmployedIncome_charcoal.png");
}
.tile[name="Partnership income"] .tileIcon {
	background-image: url("icons/tileIcons/partnershipIncome_charcoal.png");
}
.tile[name="Pension income"] .tileIcon {
	background-image: url("icons/tileIcons/pensionIncome_charcoal.png");
}
.tile[name="Land and property income"] .tileIcon {
	background-image: url("icons/tileIcons/Property_charcoal.png");
}
.tile[name="Capital disposals"] .tileIcon {
	background-image: url("icons/tileIcons/capitalDisposals_charcoal.png");
}
.tile[name="Non-UK residence and domicile"] .tileIcon {
	background-image: url("icons/tileIcons/nonUKResidentDomicile_charcoal.png");
}
.tile[name="Personal pension contributions"] .tileIcon {
	background-image: url("icons/tileIcons/pensionContributions_charcoal.png");
}
.tile[name="Life insurance gains"] .tileIcon {
	background-image: url("icons/tileIcons/lifeInsurance_charcoal.png");
}
.tile[name="Other income"] .tileIcon {
	background-image: url("icons/tileIcons/patentRights_charcoal.png");
}
/* White icons */

.tile[name="Interest, dividends and pension income"].selected .tileIcon,
.tile[name="Interest, dividends and pension income"]:hover .tileIcon {
	background-image: url("icons/tileIcons/interestDividends.png");
}
.tile[name="Employment income"].selected .tileIcon,
.tile[name="Employment income"]:hover .tileIcon {
	background-image: url("icons/tileIcons/employmentIncome.png");
}
.tile[name="Self-employment income"].selected .tileIcon,
.tile[name="Self-employment income"]:hover .tileIcon {
	background-image: url("icons/tileIcons/selfEmployedIncome.png");
}
.tile[name="Partnership income"].selected .tileIcon,
.tile[name="Partnership income"]:hover .tileIcon {
	background-image: url("icons/tileIcons/partnershipIncome.png");
}
.tile[name="Pension income"].selected .tileIcon,
.tile[name="Pension income"]:hover .tileIcon {
	background-image: url("icons/tileIcons/pensionIncome.png");
}
.tile[name="Land and property income"].selected .tileIcon,
.tile[name="Land and property income"]:hover .tileIcon {
	background-image: url("icons/tileIcons/Property.png");
}
.tile[name="Capital disposals"].selected .tileIcon,
.tile[name="Capital disposals"]:hover .tileIcon {
	background-image: url("icons/tileIcons/capitalDisposals.png");
}
.tile[name="Non-UK residence and domicile"].selected .tileIcon,
.tile[name="Non-UK residence and domicile"]:hover .tileIcon {
	background-image: url("icons/tileIcons/nonUKResidentDomicile.png");
}
.tile[name="Personal pension contributions"].selected .tileIcon,
.tile[name="Personal pension contributions"]:hover .tileIcon {
	background-image: url("icons/tileIcons/pensionContributions.png");
}
.tile[name="Life insurance gains"].selected .tileIcon,
.tile[name="Life insurance gains"]:hover .tileIcon {
	background-image: url("icons/tileIcons/lifeInsurance.png");
}
.tile[name="Other income"].selected .tileIcon,
.tile[name="Other income"]:hover .tileIcon {
	background-image: url("icons/tileIcons/patentRights.png");
}
@media only screen and (max-width: 960px) {
	.tileGrid {
		grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
		grid-auto-rows: minmax(0, auto);
	}
	.tile .activeArea {
		min-height: 125px;
		padding: 1rem;
	}
	.tile .close {
		top: -0.5rem;
		right: -0.75rem;
		background-color: var(--charcoal);
	}
}
.infoBox {
	background: var(--paleCharcoal_30);
	padding: 1rem;
	font-size: 1rem;
	line-height: 1.5em;
	border-left: 0.5rem solid var(--paleCharcoal);
	margin-top: 1.5rem;
}
@media only screen and (max-width: 960px) {
	.tile .activeArea {
		justify-content: center;
	}
	.tileIcon {
		display: none;
	}
}
/* SurveyJS */

.sv_main .sv_custom_header + div {
	flex: 1;
	display: flex;
}
.panel-body,
.card-block {
	flex: 1;
	display: flex;
	flex-direction: column;
	margin: 0 !important;
}
.progress + div[style="display: table;"] + div {
	flex: 1;
}
.progress + div[style="display: table;"] + div,
.panel-footer,
.card-footer {
	width: 100%;
}
.page-footer,
.card-footer {
	flex: none !important;
}
.sv_p_container {
	display: flex;
	flex-direction: column;
	background-color: white;
	border: none;
	padding: 0 0 1rem;
	margin: 0;
	border-top: 3px solid var(--slate);
}
.sv_p_container .sv_p_container {
	padding: 0 !important;
	margin: 0 !important;
	border: none;
}
/* Four different ways to eliminate the top border at head of survey */

h4 + div + .sv_row .sv_p_container:before,
.survey h4 + .sv_row .sv_p_container:before,
div > h4 + .sv_row .sv_p_container:before {
	display: none;
}
.progress
	+ div[style="display: table;"]
	+ div
	.sv_row:first-child
	.sv_p_container:before {
	display: none;
}
/* Question styling */

.sv_row .sv_qstn {
	float: none;
	width: 100%;
	padding: 1rem 0 0 !important;
}
.sv_row .sv_qstn h5 {
	font-size: 1rem;
	margin: 0 0 1rem;
	white-space: pre-line;
}
.sv_row .sv_qstn h5 + .small > span,
.sv_p_description {
	font-size: 14px;
	line-height: 1.5em;
	margin-bottom: 1.5rem;
	border-left: 0.5rem solid var(--paleCharcoal_plus30);
	background-color: var(--paleCharcoal);
	padding: 1rem 1.5rem !important;
	display: block;
	/*white-space: pre-wrap;*/
}
.sv_row .sv_qstn h5 + .small > span:empty {
	display: none;
}
.sv_p_description {
	margin-bottom: 0;
}
.sv_row div[style*="width: 33.3333%"],
.sv_row div[style*="width: 50%"] {
	padding-right: 0.75em !important;
}
h4 {
	font-size: 28px;
	margin: 0;
	font-weight: 700;
	padding: 25px 1.5rem;
	line-height: 1em;
}
.panel-body > div > h4,
.sv_page_title {
	font-family: var(--fontBold);
	text-align: center;
	color: white;
	line-height: 1.2em;
	padding: 1.5rem;
	margin: 0;
	display: flex;
	height: var(--surveyHeader);
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	position: relative;
	z-index: 1;
}
.page-footer:before,
.page-footer:after,
.panel-body > div > h4:before,
.panel-footer:before,
.panel-body > div > h4:after,
.panel-footer:after,
.sv_page_title:before,
.sv_page_title:after {
	content: "";
	display: block;
	position: absolute;
	height: 100%;
	background-color: var(--charcoal);
	top: 0;
	bottom: 0;
	z-index: -1;
	width: 50vw;
}
.panel-body > div > h4 + .small:before,
.panel-body > div > h4 + .small:after {
	height: 50%;
}
.page-footer:before,
.panel-body > div > h4:before,
.panel-footer:before,
.panel-body > div > h4 + .small:before,
.sv_page_title:before {
	left: 50%;
}
.page-footer:after,
.panel-body > div > h4:after,
.panel-footer:after,
.panel-body > div > h4 + .small:after,
.sv_page_title:after {
	right: 50%;
}
.page-footer:before,
.panel-footer:before,
.page-footer:after,
.panel-footer:after {
	opacity: 0.95;
}
.panel-body > div > h4,
.sv_page_title,
.panel-footer,
.card-footer,
.page-footer {
	min-width: var(--oldiPhone);
	margin-left: -1.5rem;
	margin-right: -1.5rem;
}
.panel-footer,
.card-footer,
.page-footer {
	padding: 2rem 0 2rem 0 !important;
}
.sv_p_title {
	font-size: 20px;
	margin: 1.25rem 0 0 0 !important;
	padding: 0 0 1.25rem !important;
	text-align: left;
	line-height: 1.3em;
	background-color: transparent;
	color: var(--charcoal);
	display: block;
	height: auto;
	text-align: left;
	border-bottom: 1px solid var(--paleCharcoal);
	letter-spacing: 0.015em;
	font-family: var(--fontBold);
}
.sv_p_title + div .sv_p_title {
	font-size: 1rem;
	text-transform: none;
	border-bottom: none;
	margin: 0;
	padding: 0 !important;
}
.panel-body > div > h4 + .small {
	all: unset;
}
.panel-body > div > h4 + .small > span:empty {
	display: none;
}
.panel-body > div > h4 + .small > span {
	text-align: center;
	color: white;
	background-color: var(--slate);
	margin-bottom: 0;
	font-size: 1rem;
	height: 3rem;
	line-height: 3rem;
	margin-left: -1.5rem;
	margin-right: -1.5rem;
	position: relative;
	display: block;
}
.panel-body > div > h4 + .small span {
	margin: 0;
}
.sv-question__title {
	font-weight: bold;
}
.sv_p_container .sv_row + .sv_row {
	border-top: 1px solid var(--paleCharcoal);
	margin-top: 1rem;
}
.small > span:empty {
	display: inline;
}
/* inputs */

input,
button,
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
input[type="text"],
input[type="date"],
input[type="number"] .sv_q_text_root,
input:not([type="button"]):not([type="reset"]):not([type="submit"]):not(
		[type="image"]
	):not([type="checkbox"]):not([type="radio"]),
.sv_main .sv_container .sv_body .sv_p_root .sv_q select,
textarea,
.form-control {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	font-size: 14px;
	line-height: 1.4em;
	border: 1px solid #ccc;
	padding: 0.5em 1rem;
	background-color: var(--paleCharcoal);
	border-radius: 5px;
	transition: 0.5s;
	-webkit-appearance: none;
	height: 40px;
}
.form-control:focus {
	border: 2px solid var(--charcoal_50) !important;
	box-shadow: none;
	background-color: white !important;
}
input[type="number"]::-webkit-inner-spin-button {
	display: none;
}
.sv_q_file_input {
	background-color: white;
}
textarea {
	margin-bottom: 1.5rem;
	height: auto;
	padding: 1rem 1.5rem;
}
input[type="number"],
input[type="date"] {
	max-width: 272px;
	min-width: 10rem;
}
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */

input[type="number"] {
	-moz-appearance: textfield;
}
/* Change Autocomplete styles in Chrome*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: 1px solid var(--ocean) !important;
	-webkit-text-fill-color: var(--charcoal);
	-webkit-box-shadow: 0 0 0px 1000px rgba(2, 165, 226, 0.05) inset;
	box-shadow: 0 0 0px 1000px rgba(2, 165, 226, 0.05) inset;
	transition: background-color 5000s ease-in-out 0s;
}
/* progress bar */

.progress + div[style="display: table;"] + div + .progress {
	display: none;
}
.sv_main .progress-bar {
	height: 100%;
	min-width: 100px;
	position: relative;
	margin: 0 !important;
	background-color: hsl(196deg 98% 15%);
}
.sv_main .progress {
	height: 1rem;
	background-color: var(--ocean_plus30);
	margin: 3rem 1rem 0;
	width: calc(100% - 2rem) !important;
	overflow: visible;
	border-radius: 0;
}
/* progress bar */

.sv_main .progress,
.sv_progress {
	margin: 0 !important;
	position: fixed;
	top: var(--masthead);
	left: 0;
	right: 0;
	width: 100% !important;
	height: var(--progressbar);
	z-index: 99;
}
.card-block > .progress:first-child,
.panel-body > .progress:first-child .progress-bar > span,
.sv_progress_bar > span {
	font-size: 0;
}
.sv_main .progress-bar > span {
	position: absolute;
	top: -1.75em;
	display: inline-block;
	white-space: nowrap;
	font-size: 0;
}
.panel-footer,
.card-footer,
.page-footer {
	min-height: 2em;
	display: flex;
	justify-content: space-between;
	width: 100%;
	background: white;
	padding: 1rem 0 0 1rem !important;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	border-top: 1px solid var(--paleCharcoal);
	z-index: 70;
	z-index: 100;
}
.page-footer {
	padding-bottom: 1rem;
}
.panel-footer,
.card-footer,
.page-footer {
	padding: 1rem 2.75rem !important;
	border: none;
	background-color: transparent;
	/*max-width: var(--fixedWidth);*/
	margin: 0 auto;
	min-height: var(--navbar);
	/*background-color: rgba(51, 51, 51, 0.9);*/
}
.btn,
.button,
button,
input[type="button"] {
	font-family: Proxima Nova Regular, sans-serif;
	font-family: var(--fontRegular);
	font-size: 1rem;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	-webkit-appearance: none;
	background-color: #444;
	border: 1px solid #444;
	color: #fff;
	padding: 0.5rem;
}
.btn,
.button {
	line-height: 1.3em;
	border-radius: 0 !important;
	width: 100%;
	min-height: 40px;
}
.button.primary,
.btn.primary,
.button.sv_next_btn,
.button:last-child {
	background-color: var(--ocean);
	border: 1px solid var(--ocean);
	color: white;
}
.button.secondary,
.btn.secondary {
	background-color: var(--charcoal_50);
	border-color: var(--charcoal_50);
}
a.btn,
a.button,
a.button:hover,
a.btn:hover {
	text-decoration: none;
}
/* Bootstrap button hover/focus */

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(101, 124, 145, 0.5);
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	background-color: var(--slate);
	border-color: var(--slate);
}
.sv-paneldynamic__add-btn,
.sv-paneldynamic__add-btn--list-mode,
.sv_main.sv_main .sv-paneldynamic__add-btn,
.button.sv-paneldynamic__remove-btn {
	margin-top: 0;
	background-color: var(--charcoal);
	border: 1px solid var(--charcoal);
	width: auto;
	padding: 0 1.5rem;
	display: inline-block;
	margin-bottom: 1rem;
}
.sv-paneldynamic__add-bt:hover,
.sv-paneldynamic__add-btn--list-mode:hover,
.button.sv-paneldynamic__add-bt:hover {
	background-color: var(--slate);
	border: 1px solid var(--slate);
}
.sv-paneldynamic__add-btn,
.sv-paneldynamic__add-btn--list-mode,
.button.sv-paneldynamic__add-btn {
	margin-top: 0 !important;
	float: right;
	position: relative;
	z-index: 1;
}
.button.sv-paneldynamic__add-btn,
.button.sv-paneldynamic__add-btn--list-mode,
.button.sv_main.sv_main .sv-paneldynamic__add-btn {
	float: right;
	position: relative;
	z-index: 1;
}
.sv_main .sv-paneldynamic__remove-btn {
	margin-top: 1.5rem !important;
}
/* Add button vertical position */

input[class*="add-btn"] {
	margin-top: 0 !important;
}
input + hr + input[class*="add-btn"] {
	margin-top: 0 !important;
}
.sv_prev_btn,
.sv_next_btn {
	background-image: url(icons/arrow-left-large-white.svg);
	background-repeat: no-repeat;
	background-position: left 0.5rem center;
	width: calc(50% - 0.75rem);
}
.button.sv_prev_btn {
	background-image: url(icons/arrow-left-large-white.svg);
}
.button.sv_prev_btn:hover {
	background-image: url(icons/arrow-left-large-white.svg);
}
.sv_next_btn {
	background-image: url(icons/arrow-right-large-white.svg);
	background-position: right 0.5rem center;
}
.button:hover,
.button.primary:hover {
	background-color: var(--slate);
	border-color: var(--slate);
	color: white;
	cursor: pointer;
}
.button:focus {
	outline: 0;
	box-shadow: none;
}
.button.sv_complete_btn:focus,
.button.complete:focus {
	border-color: var(--green);
}
.panel-footer .button {
	margin-right: 0 !important;
	flex: 1;
	/* max-width: calc(50% - 1rem); */
}
.button.sv_complete_btn,
.button.complete {
	background-color: var(--green);
	color: white;
	border-color: var(--green);
}
.button.sv_complete_btn:hover,
.button.complete:hover {
	border-color: var(--darkRed);
	background-color: var(--darkRed);
}
.sv_q_file_input::-webkit-file-upload-button{
	cursor:pointer;
}
/* on complete alert*/
.onCompleteMessageHeader{
	color:#008fd2;
	text-transform: none !important;
	margin-left:auto;
	margin-right:auto;
	border-bottom:none!important;
	padding-top: 25px;

}
.onCompleteMessageModalBody{
	padding-bottom:0px;
}
.onCompleteMessageBody{
	list-style-type: disc;
	text-align: left;
	padding-bottom:0px;
	padding-left:20px;
	padding-right:20px;
}
.onCompleteMessageBody li::before{
	display:none;
}
.submitMedicalExpenses{
	background-color: var(--slate)!important;
	border-color: var(--slate)!important;
}
.submitMedicalExpenses:hover{
	background-color: var(--charcoal)!important;
	border-color: var(--charcoal)!important;
}
/* alerts */

.sv_row .sv_qstn .sv_q_title[aria-label*="tick"],
.sv_row .sv_qstn h5[aria-label*="tick"] {
	font-size: 14px;
	font-style: italic;
}
.sv_q_required_text {
	color: var(--darkRed);
}
.alert,
.sv_main .alert:not([style*="display: none"]):not([style*="display:none"]) {
	border: none;
	background-color: var(--darkRed);
	color: white;
	border-radius: 5px;
	margin: 0;
	padding: 0.75rem 3rem;
}
.sv_main .alert {
	margin: 1rem 0 !important;
}
.sv_qstn.has-error h5 > span + span {
	font-family: "consolas";
	font-weight: 700;
	color: var(--darkRed);
	margin-left: 3px;
	font-size: 1.3rem;
	line-height: 1rem;
	display: inline-block;
	vertical-align: middle;
	height: 1rem;
}
/* "Thank you" page */

.successPage {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--ocean);
	padding: 0 1.5rem;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	flex-direction: column;
	text-align: center;
	overflow-y: hidden;
	z-index: 2;
}
@keyframes spinner {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate(0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate(360deg);
	}
}
.spin {
	margin-left: 100px;
}
.spin::before {
	animation: 1.5s linear infinite spinner;
	animation-play-state: inherit;
	border: solid 5px #cfd0d1;
	border-bottom-color: #1c87c9;
	border-radius: 50%;
	content: "";
	height: 100px;
	width: 100px;
	transform: translate3d(-50%, -50%, 0);
	will-change: transform;
	display: block;
}
.successGraphic {
	background-image: url(icons/verified.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 200px;
	width: 200px;
	min-height: 200px;
	margin: 0 auto 1.5rem;
}
.successMessage {
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.successMessage h2 {
	font-size: 3rem;
	line-height: 1em;
	margin-bottom: 0.25em;
}
.successMessage h3 {
	font-size: 1.5rem;
	margin-bottom: 2rem;
}
.successMessage .page-footer {
	position: static;
	padding: 0 !important;
	min-width: 100%;
}
.successMessage .button {
	background-color: transparent;
	height: 3.5rem;
	font-size: 1.25rem;
	border-color: white;
	max-width: 100%;
}
.successMessage .button:hover {
	background-color: white;
	color: var(--ocean);
}
.successMessage .page-footer:before,
.successMessage .page-footer:after {
	content: none;
}
/* success updates 11/24/2020 */

.successPage {
	z-index: 1;
}
.successMessage {
	max-width: 480px;
}
.successMessage p {
	margin-bottom: 2.5rem;
	font-size: 1rem;
}
.successMessage h3 {
	margin-bottom: 1rem;
}
.successMessage .button {
	border-width: 2px;
	border-radius: 0 !important;
}
.successPage {
	background: linear-gradient(185deg, var(--ocean), #1569b7);
}
.successMessage .smallPrint {
	font-size: 14px;
	line-height: 1.7em;
	background: rgb(51 51 51 / 0.2);
	padding: 0.5rem 1rem;
	text-align: left;
	border-left: 2.5rem solid rgb(51 51 51 / 0.25);
	position: relative;
}
.successMessage .smallPrint strong {
	font-family: var(--fontBold);
	font-weight: 400;
}
.successMessage .smallPrint:before {
	content: "i";
	display: inline-flex;
	width: 1.5rem;
	height: 1.5rem;
	border: 1px solid var(--white);
	font-size: 0.9rem;
	justify-content: center;
	align-items: center;
	margin: 0 0.5rem;
	border-radius: 100%;
	text-transform: lowercase;
	font-family: monospace;
	position: absolute;
	left: -2.5rem;
}
/* File upload */

.sv_q_file,
input[type="date"],
.sv_main .alert:not([style*="display: none"]):not([style*="display:none"]) {
	position: relative;
	border: 1px solid var(--charcoal);
	display: flex;
	z-index: 10;
	background-image: url(icons/upload.svg);
	background-repeat: no-repeat;
	background-position: right 1rem center;
	border-radius: 5px;
}
.sv_q_file {
	background-color: white;
	height: 40px;
	font-size: 14px;
	color: var(--charcoal);
	border-radius: 1.5rem;
}
.sv_q_file:before {
	content: "Choose Files";
	position: absolute;
	z-index: -1;
	line-height: 38px;
	padding-left: 1rem;
}
.sv_q_file_input {
	opacity: 0;
}
/* old styles - refactored */

.sv_q_file {
	background-color: #fff;
	height: auto;
	font-size: 14px;
	color: #333;
	color: var(--charcoal);
	flex-direction: column;
	background-image: none;
	overflow: hidden;
	margin-bottom: 0;
	border: none;
	border-radius: 0;
	position: relative;
	min-height: 3.5rem;
}
.sv_q_file:before {
	content: "";
	position: absolute;
	z-index: -1;
	line-height: 38px;
	padding-left: 1rem;
} 
.sv_q_file_input {
	opacity: 0;
	position: absolute !important;
	width: calc(100% - 175px) !important;
	border-radius: 0 !important;
	line-height: 40px !important;
	height: 40px !important;
}
.medicalExpensesSurvey .sv_q_file_input{
	width: 155px !important;
	margin-left:0px!important;
}
.sv_q_file:before {
	content: "Upload file";
	background-color: var(--charcoal);
	color: white;
	position: absolute;
	display: block;
	padding: 0 1.5rem;
	border-radius: 5px;
	text-align: center;
	width: 33.333%;
	height: 40px;
	line-height: 40px;
	transition: 0.5s;
	margin-left: 0;
	cursor: pointer;
}
.sv_q_file:hover:before {
	background-color: var(--slate);
	cursor: pointer;
}
/* New styles */

.sv_q_file .sv_q_file_preview {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 1rem 1.5rem;
	margin: 0;
	border: none;
	border-top: 1px solid var(--paleCharcoal);
	align-items: center;
}
.sv_q_file_preview:first-child {
	margin-top: calc(1rem + 40px);
}
.sv_q_file_preview a {
	white-space: nowrap;
	display: flex;
	align-items: center;
	font-family: var(--fontRegular);
	color: var(--charcoal);
	pointer-events: none;
}
.sv_q_file_preview a:before,
.cd-timeline .status:before {
	content: "";
	padding: 1em 0.5em;
	height: 4.25rem;
	width: 4rem;
	display: block;
	margin-right: 1rem;
	white-space: normal;
	line-height: 1.1em;
	font-size: 12px;
	text-align: center;
	background-image: url(icons/file.svg);
	background-size: 100%;
	background-repeat: no-repeat;
}
a[download*=".pdf"]:before,
a[download*=".PDF"]:before {
	background-image: url(icons/pdf.svg);
}
a[download*=".png"]:before,
a[download*=".jpg"]:before,
a[download*=".gif"]:before,
a[download*=".tiff"]:before,
a[download*=".svg"]:before,
a[download*=".bmp"]:before,
a[download*=".jpeg"]:before,
a[download*=".PNG"]:before,
a[download*=".JPG"]:before,
a[download*=".GIF"]:before,
a[download*=".TIFF"]:before,
a[download*=".SVG"]:before,
a[download*=".BMP"]:before,
a[download*=".JPEG"]:before {
	background-image: url(icons/img.svg);
}
a[download*=".xls"]:before,
a[download*=".xlsx"]:before,
a[download*=".numbers"]:before,
a[download*=".XLS"]:before,
a[download*=".XLSX"]:before,
a[download*=".NUMBERS"]:before {
	background-image: url(icons/xls.svg);
}
a[download*=".doc"]:before,
a[download*=".docx"]:before,
a[download*=".pages"]:before,
a[download*=".DOC"]:before,
a[download*=".DOCX"]:before,
a[download*=".PAGES"]:before {
	background-image: url(icons/doc.svg);
}
a[download*=".ppt"]:before,
a[download*=".pptx"]:before,
a[download*=".PPT"]:before,
a[download*=".PPTX"]:before {
	background-image: url(icons/ppt.svg);
}
.sv_q_file .sv_q_file_remove,
.sv_q_file .sv_q_file_remove_button {
	display: block;
	border: 1px solid var(--paleCharcoal_plus30);
	padding: 0 1.25em;
	line-height: 40px;
	border-radius: 0 !important;
	color: var(--paleCharcoal_plus30);
	font-size: 14px;
	width: 135px;
	margin: 0 !important;
	transition: 0.5s;
	height: 40px;
	min-height: 40px;
}
.sv_q_file_remove_button {
	background: none;
	position: absolute;
	right: 1.5rem;
}
.sv_q_file > input[type="file"] {
	margin-left: 1.5rem;
}
.sv_q_file > input[type="file"]:hover {
	cursor: pointer;
}
.sv_q_file_preview a {
	opacity: 1;
}
.sv_q_file_preview a:hover {
	text-decoration: none;
	opacity: 0.8;
}
.sv_q_file .sv_q_file_remove {
	display: none;
}
.sv_q_file .sv_q_file_preview svg {
	display: block;
	width: 2rem;
	fill: var(--paleCharcoal_plus30);
	transition: 0.5s;
}
.sv_q_file:before,
.sv_q_file_remove_button {
	margin: 0 !important;
	width: 9.5rem;
}
.sv_q_file:before:hover {
	cursor: pointer;
}
.sv_q_file .sv_q_file_preview svg:hover {
	cursor: pointer;
	fill: var(--darkRed);
}
.sv_q_file .sv_q_file_remove_button:hover {
	border-color: var(--darkRed);
	background-color: var(--darkRed);
	color: white;
}
.sv_q_file_preview a {
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-align: left;
	max-width: 100%;
	width: calc(5rem + 15em);
	margin-bottom: -6px;
}
.sv_q_file_preview a:before {
	display: inline-block;
	vertical-align: middle;
	padding: 0;
}
.sv_q_file .sv_q_file_preview svg {
	min-width: 2rem;
}
/* alerts/Warnings */

.alert,
.sv_main .alert:not([style*="display: none"]):not([style*="display:none"]) {
	border: none;
	background-image: url(icons/alert-white.svg);
	background-repeat: no-repeat;
	background-position: 0.75rem 11px;
}
/* date input */

input[type="date"] {
	background-image: url(icons/calendar.svg);
	background-image: none;
}
input[type="date"]::-webkit-inner-spin-button {
	display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
	display: none;
}
/* radio button and checkbox over-rides */

.sv_qstn .sv_q_select_column {
	max-width: 100% !important;
	padding-right: 0 !important;
}
.sv_qstn fieldset.sv_qcbc {
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
}
.sv_qstn div[class*="sv-q"] {
	padding-right: 0;
}
.sv_qstn fieldset .radio,
.sv_qstn fieldset .checkbox {
	max-width: 100% !important;
	width: 100%;
	margin-bottom: 1rem;
}
.sv_qstn fieldset .radio {
	margin-bottom: 10px;
}
.sv_qcbx .checkbox label {
	display: flex !important;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0 0 0.5rem;
}
.sv_qcbx .checkbox label input[type="checkbox"] {
	margin-top: 3px;
}
/* Radio Buttons and Checkboxes */

.radio,
.checkbox {
	background-image: url(icons/radio-button_deselected.svg);
	background-repeat: no-repeat;
	background-position: top left;
	min-height: 24px;
	transition: 0.5s;
	line-height: 24px;
}
.radio label,
.checkbox label {
	cursor: pointer;
	position: relative;
	padding-left: 2rem;
	margin: 0 !important;
}
.radio input,
.checkbox input {
	opacity: 0;
	position: absolute;
	left: 0;
}
.radio textarea,
.checkbox textarea {
	width: 100%;
	margin-top: 1rem;
	height: 0;
	overflow: hidden;
	transition: 0.5s;
}
.radio.checked textarea,
.checkbox.checked textarea {
	height: 100%;
	width: 100%;
}
.radio.checked {
	background-image: url(icons/radio-button_selected.svg);
}
.checkbox {
	background-image: url(icons/check-button_deselected.svg);
}
.checkbox.checked {
	background-image: url(icons/check-button_selected.svg);
}
/* unexpected display */

#surveyResult {
	white-space: pre-wrap;
	background-color: white;
	margin: 1rem auto;
	padding: 1rem 1.5rem;
	border: 1px solid var(--paleCharcoal);
	border-radius: 0.5rem;
}
#surveyResult:empty {
	display: none;
}
/* bootstrap litter */

.form-group {
	margin: 0;
}
/* save button */

.saveAsYouGo {
	width: 100%;
	height: 40px;
	background-color: var(--ocean);
	border: none;
	color: white;
	font-size: 14px;
}
.surveySave {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 100;
}
.page.survey .surveySave {
	display: block;
}
.page.survey .surveySave .button {
	border-color: white;
}
/* slider */

.tns-nav {
	display: flex;
	justify-content: center;
	height: 1rem;
	margin: 2.5rem 0;
}
.tns-nav button {
	height: 1rem;
	width: 1rem;
	border: 1px solid #a1a1a1;
	background-color: #e7e7e7;
	margin: 0 0.25rem;
	border-radius: 0 !important;
	padding: 0;
}
.tns-nav .tns-nav-active {
	background-color: var(--ocean);
	border-color: var(--ocean);
}
.tns-outer {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.tns-ovh {
	flex: 1;
}
.tns-item {
	padding-top: 2.5rem;
}
.placeholder {
	background-image: url(images/dummy_BG1.jpg);
	background-size: 100%;
	width: 100%;
	padding-top: 54.166666666667%;
	height: 0;
}
/* on-page-forms */

.page form label {
	font-size: 1rem;
	margin: 1rem 0;
}
.page form input[type="text"],
.page form textarea,
.sv_qstn .form-control {
	margin-bottom: 1.5rem;
}
.page form textarea {
	min-height: 18vh;
	padding: 1rem;
}
/* 404 Error Page */

.page.errorPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: transparent;
}
.errorPage .desktopWrapper {
	flex-direction: column;
	width: auto;
	padding: 1.5rem 1.5rem 0;
	text-align: center;
}
.errorPage h2 {
	font-family: var(--fontBold);
	font-size: 25.5vw;
	margin: 0;
	line-height: 1em;
	font-size: 80px;
	font-size: calc(80px + (192 - 80) * ((100vw - 320px) / (1200 - 320)));
}
.errorPage h3 {
	text-transform: uppercase;
	margin: 2.5rem 0 3.5rem;
}
.errorPage .button {
	margin: 0 !important;
}
/* contact us page */

.page.contactUs {
	/* border-top: 0.5rem solid var(--slate); */
	/* padding: 2rem 5rem 5rem; */
	padding: 1rem 1.5rem;
	text-align: left;
}
.page.contactUs h2 {
	text-align: center;
	font-size: 1.5rem;
	padding: 2.5rem;
	color: var(--ocean);
}
.page.contactUs header {
	border-bottom: 1px solid var(--paleCharcoal);
	display: none;
}
.page.contactUs label {
	margin-top: 0;
	display: block;
	font-family: var(--fontBold);
	margin-bottom: 0.75rem;
}
.page.contactUs .button {
	margin: 0 auto;
}
.page.contactUs .pageContent {
	display: flex;
	flex-direction: column;
	padding-top: 0;
}
.page.contactUs .pageContent section {
	flex: 1 1;
	display: flex;
	flex-direction: column;
}
.page.contactUs .pageContent section textarea {
	flex: 1 1;
	margin-bottom: 0;
	font-size: 1rem;
}
.page.contactUs input,
.page.contactUs textarea {
	background-color: var(--paleCharcoal_30) !important;
}
.page.contactUs .buttonDeck {
	margin-top: 1.5rem;
}
@media only screen and (max-width: 812px) {
	.productTaskbar {
		border-bottom: 1px solid var(--paleCharcoal);
	}
	.panel-body > div > h4 + .sv_row > .sv_p_container {
		border-top: none;
	}
	.sv_p_container {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	.globalFooter {
		padding-bottom: var(--navbar);
		background-color: var(--paleCharcoal);
		padding-top: 1.5rem;
	}
	.button.editSources {
		margin: 1.5rem;
		width: calc(100% - 3rem);
	}
	/* contact us page */
	.page.contactUs {
		padding: 0;
		border: none;
	}
	.page.contactUs .pageContent {
		padding: 1rem 0.75rem;
	}
	.page.contactUs header {
		border: none;
	}
	.page.contactUs header h2 {
		margin-bottom: 0;
	}
	.page.contactUs .buttonDeck {
		margin-top: 0;
	}
}
/* introPage / welcome page */

.introPage h3 {
	font-family: var(--fontBold);
	margin-bottom: 1.5rem;
	font-size: 1.5rem;
}
.introPage h2 {
	font-weight: 700;
	padding: 0 0 1.5rem !important;
	color: var(--charcoal);
	border-bottom: 1px solid var(--paleCharcoal);
	line-height: 1.3em;
}
.introPage .pageContent {
	display: flex;
	flex-direction: column;
}
.howtoGuide {
	flex: 1;
	line-height: 1.5em;
}
.howtoNotes {
	background-color: var(--paleCharcoal);
	padding: 0;
	font-size: 1rem;
	display: flex;
}
.howtoNotes:before {
	content: "";
	display: flex;
	font-size: 8rem;
	color: #fff;
	background-color: var(--paleCharcoal_plus30);
	align-items: center;
	justify-content: center;
	width: 4rem;
	background-image: url(icons/asterisk.svg);
	background-repeat: no-repeat;
	background-position: 50%;
	padding: 1rem 0;
	min-height: 4rem;
}
.howtoNotes ul {
	margin-bottom: 0;
	flex: 1 1;
	padding: 1rem 1.5rem;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
}
.introPage li:before {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-top: 0.333rem solid transparent;
	border-bottom: 0.333rem solid transparent;
	border-left: 0.5rem solid var(--charcoal);
	margin-top: 0.5rem;
}
.introPage li:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
}
.introPage .howtoNotes li:before {
	border-left-color: var(--paleCharcoal_plus30);
	margin-top: 0.45rem;
}
@media only screen and (max-width: 480px) {
	.introPage h2 {
		padding-bottom: 1.5rem !important;
		margin-bottom: 1.5rem;
	}
	.howtoNotes {
		padding: 1.5rem 1rem;
	}
	.howtoNotes:before {
		content: none;
	}
}
/* Bootstrap over-rides / Modal Dialog */

.cancel {
	background-color: var(--darkRed);
	border-color: var(--darkRed);
}
.modal-title {
	padding: 1rem 1.5rem 0;
}
.modal-dialog .modal-content {
	border-radius: 5px;
	text-align: center;
	font-size: 1rem;
}
.modal-dialog .modal-body {
	line-height: 1.4em;
	font-family: var(--fontRegular);
}
.modal-header {
	font-family: var(--fontBold);
	font-size: 1.25rem;
}
.modal-header .close {
	font-family: var(--fontBold);
	color: var(--charcoal);
	font-size: 3rem;
	line-height: 1.5rem;
}
.modal-footer {
	border: none;
	padding-top: 0;
}
.modal-footer .button:last-child {
	margin-right: 0 !important;
}
.modal.requestChanges textarea {
	margin-bottom: 0;
	padding: 1rem;
	font-size: 1rem;
}
.modal.requestChanges h3 {
	text-align: left;
	margin-bottom: 1.25rem;
	font-family: var(--fontBold);
}
/* tabular hack */

.table,
tbody {
	display: block;
	width: 100%;
	margin: 0;
	color: var(--charcoal);
}
.table tr {
	display: flex;
	width: 100%;
	flex-direction: column;
}
.table td,
.table th {
	display: block;
	padding: 0;
	border: none;
}
.table td > span:first-child {
	margin-bottom: 0.5rem;
	display: block;
}
/* Tablet Breakpoint */

@media only screen and (min-width: 480px) {
	.button {
		max-width: 12rem;
	}
	.page > header h2 + h3 {
		margin-top: -1rem;
	}
}
/* Desktop Breakpoint */

@media only screen and (min-width: 813px) {
	body {
		font-size: 16px;
		background-color: var(--paleCharcoal);
	}
	.desktopWrapper {
		align-items: center;
	}
	.branding,
	.globalNav {
		width: 220px;
	}
	.globalNav {
		display: flex;
		justify-content: flex-end;
	}
	.globalFooter .desktopWrapper {
		flex-direction: row;
	}
	.branding h2,
	h1 {
		display: block;
	}
	h4,
	.survey h4 {
		margin: 0 0 1.5rem;
	}
	.survey h4 {
		margin-top: 0.5rem;
	}
	.page {
		background-color: white;
		padding: 2rem 3.5rem;
		border-radius: 0;
	}
	.page.survey {
		background-color: var(--paleCharcoal);
		margin-top: 0;
		padding: 0;
		border-radius: 0;
	}
	.page > header h2 {
		margin: 0 0 0.5rem;
	}
	.page > header h3 {
		margin: 0 0 1.5rem;
	}
	.sectionNav nav {
		margin: 0;
		background-color: transparent;
		display: block;
		font-size: 14px;
	}
	.page > header,
	.pageContent {
		padding: 0;
	}
	.page.survey {
		padding: 0;
		font-size: 1rem;
		height: calc(100% + 1rem);
		margin-top: -1rem;
	}
	.sv_main .sv_custom_header + div {
		display: block;
	}
	.panel-body,
	.card-block {
		padding: 0;
	}
	.panel-footer,
	.card-footer,
	.page-footer {
		padding-bottom: 3.5rem;
	}
	.sv_p_container:before {
		display: none;
	}
	.sv_p_container {
		margin: 0;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		border-radius: 0;
		padding: 0 1.5rem 1.5rem !important;
		width: 100%;
		margin-top: 2rem !important;
		border-top: 0.5rem solid var(--slate) !important;
	}
	.sv_p_container .sv_p_container {
		border-top: none !important;
		background: transparent;
	}
	/* Desktop input layout */
	.menu {
		position: static;
	}
	.contentWrapper {
		display: flex;
		padding: calc(var(--masthead) + var(--progressbar) + 1rem) 1.5rem
			calc(var(--navbar) + 2rem);
		padding: calc(var(--masthead) + 1rem) 1.5rem 2rem;
	}
	.panel-footer .button {
		min-width: 12rem;
	}
	/* Survey styles */
	.sectionContent,
	.sv_main {
		min-width: 0;
		flex: 1;
	}
	.survey:after,
	.survey:before {
		display: block;
		content: none;
		width: 50vw;
		background-color: white;
		top: 0;
		position: fixed;
		z-index: -1;
		bottom: 0;
	}
	.survey:after {
		right: 0;
		opacity: 0.3;
	}
	.survey:before {
		left: 0;
	}
	.panel-body > div > h4 {
		border-radius: 0;
		/*padding-bottom: 2.5rem;*/
	}
	.panel-body > div > h4 + .small > span {
		margin-left: 0;
		margin-right: 0;
		border-radius: 5px;
		margin-top: -1.5rem;
		z-index: 1;
	}
	.sv_main .progress-bar,
	.sv_progress_bar {
		background-color: var(--ocean);
	}
	/* Desktop Pages */
	.page.filterQuestions .pageContent {
		flex: 0;
	}
	/* surveyJS */
	.progress + div[style="display: table;"] + div {
		padding: 0;
	}
	/* rows and questions */
	.sv_row {
		display: flex;
	}
	.sv_row .sv_qstn {
		flex: 1;
		width: inherit !important;
		padding: 1rem 1rem 0 0 !important;
	}
	.sv_row .sv_qstn:last-child {
		padding-right: 0 !important;
	}
	.errorPage .desktopWrapper {
		margin-top: -2em;
		padding: 0;
	}
}

/************************/

/* Page-by-page styling */

/************************/

/* Browser Warning */

.browserWarning {
	display: block;
	width: 800px;
	height: 600px;
	margin: 100px auto 0;
	text-align: center;
	position: relative;
	padding: 1rem 2.5rem;
}
.browserWarning .productTaskbar,
.browserWarning .globalFooter {
	position: static;
	display: block;
}
.browserWarning .productTaskbar {
	height: 5rem;
}
.browserWarning .branding,
.browserWarning .branding > a {
	display: block;
}
.browserWarning .productTaskbar .desktopWrapper {
	padding: 0;
}
.browserWarning .globalFooter {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}
.browserWarning .globalFooter .desktopWrapper {
	display: block;
}
.browserWarning .branding h2 {
	margin: 0;
	font-size: 13px;
	margin-left: 1rem;
	padding: 0;
	display: inline-block;
	vertical-align: top;
}
.browserWarning h2,
.browserWarning h3 {
	margin: 0;
	padding: 0 3rem;
	line-height: 1.2em;
	font-size: 3rem;
}
.browserWarning h2 {
	margin-top: 3rem;
	margin-bottom: 1em;
}
.browserWarning h3 {
	margin-bottom: 3rem;
	font-size: 1.5rem;
}
.browserWarning .button.primary {
	margin: 0 auto 3rem;
}
/* Quote page */

.quote h2,
.quote h3,
.quote h4,
.quote h5 {
	margin: 0 0 1.5rem;
	font-weight: 400;
	font-family: var(--fontRegular);
}
.quote section {
	border-bottom: 1px solid white;
	padding: 2.5rem 0;
}
.quote section h2 {
	font-size: 4rem;
	font-family: var(--fontBold);
	line-height: 1em;
	height: 1em;
	display: flex;
}
.quote section h2 span {
	font-size: 1rem;
	font-family: var(--fontRegular);
	padding-left: 1rem;
}
.quote h3 {
	font-size: 1.5rem;
}
.quote h4 {
	font-size: 1.25rem;
	padding: 0;
}
.quote section {
	border-bottom: 1px solid white;
	padding: 2.5rem 0;
}
.quote section:first-child {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.quote section:last-child {
	border: none;
}
.quote ul {
	padding: 0;
	margin: 0;
}
/* Review Tax Return Page  */

.taxYearHeader > h2 {
	font-size: 3rem;
	line-height: 1em;
	margin-bottom: 2rem;
}
.myTaxReturns h2 {
	font-size: 1.75rem;
	font-family: var(--fontRegular);
	margin: 1.5rem 0 0 !important;
}
.myTaxReturns,
.taxYearHeader {
	text-align: center;
}
.taxReview {
	width: 100%;
}
.fileDeck,
.buttonDeck {
	display: flex;
	flex-direction: row;
}
.fileDeck {
	background-color: var(--paleCharcoal);
	border-radius: 5px;
}
.taxContainer_annual {
	flex-direction: column;
	margin-right: 1rem;
	display: inline-flex;
	padding: 1.5rem 1rem;
	flex: 1;
}
.taxContainer_annual:last-child {
	margin-right: 0;
}
.taxContainer_annual header {
	flex: 1 1;
	flex-direction: column;
	display: flex;
	align-items: center;
	background: transparent;
}
.taxFileType {
	transition: 0.3s;
}
.taxFileType:hover {
	opacity: 0.7;
	cursor: pointer;
}
.taxFileType.pdf,
.taxFileType.file,
.taxFileType.folder,
.taxFileType.fileNotFound {
	width: 80px;
	height: 86px;
	margin-right: 0;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: 50%;
	background-image: url(icons/pdf.svg);
}
.descriptionYear {
	font-weight: 700;
	padding-bottom: 1rem;
	min-height:  4rem;
	text-align: center;
}
.queryButton {
	background-color: var(--slate);
	border: 0;
}
.taxContainer_borderRight {
	border-right: 4px solid var(--paleCharcoal_plus30)
}
.taxFileType.file {
	background-image: url(icons/file.svg);
}
.taxFileType.folder {
	background-image: url(icons/folder.svg);
}
.taxFileType.fileNotFound {
	background-image: url(icons/fileNotFound.svg);
	background-size: contain;
}
.fileNotFound + .taxFileDetail {
	opacity: 0.333;
}
.taxContainer_annual .button {
	background: transparent;
	color: #333;
	font-size: 14px;
	border-color: #a1a1a1;
	font-family: var(--fontRegular);
}
.descriptionType {
	display: block;
	width: 100%;
	font-weight: 700;
}
.taxFileYourName {
	margin: 0;
}
.taxFileDescription {
	text-align: center;
	color: #6F6F6F;
}
.buttonDeck {
	margin-top: 2rem;
	display: flex;
	justify-content: space-between;
}
.buttonDeck .button {
	width: calc(50% - 0.5rem);
}
.buttonDeck .button:last-child {
	margin-right: 0;
}
.taxContainer_annual footer {
	padding: 0;
}
.taxContainer_annual footer .button {
	color: white;
}
.taxContainer_annual footer .button:hover {
	text-decoration: none;
}
.viewTaxReturn{
	color:white !important;
}
/* Annual review */
.page .historyHead h2 {
	text-align: center;
	margin: 0;
	font-weight: 400;
	font-family: var(--fontRegular);
	font-size: 1.75rem;
}
.page > .historyHead {
	border-top: 1px solid hsl(0deg 0% 82%);
	padding-top: 1.5rem;
}
.page.myAccount .card {
	margin: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border-radius: 0;
	padding: 0 1.5rem 1.5rem !important;
	width: 100%;
	margin-top: 2rem !important;
	border-top: 0.5rem solid var(--slate) !important;
}
.taxReview h2 {
	margin-bottom: 1rem;
}
.taxArchive .fileDeck {
	background-color: transparent;
}
.taxContainer_annual {
	display: flex;
	align-items: center;
	justify-content: center;
}
.taxArchive .taxContainer_annual {
	background-color: var(--paleCharcoal);
	border-radius: 5px;
}
.taxReview a,
.taxReview a > .taxFileType {
	transition: 0.3s;
}
.taxReview a > .taxFileType:hover,
.taxReview a.taxFileType:hover {
	background-color: transparent !important;
	opacity: 0.5;
}
.taxFileDescription {
	margin: 1rem 0 0;
	font-size: 1rem;
}
.taxReview .taxFileType {
	padding: 0;
}
.taxReview .taxContainer_annual header {
	padding: 0;
}
.taxReview .statusMessage {
	margin-bottom: 1rem;
	background-color: var(--gold);
	padding: 0.5rem 1rem;
	border-radius: 5px;
	color: white;
}
.taxReview .statusMessage:empty {
	display: none;
}
@media only screen and (max-width: 480px) {
	.myTaxReturns,
	.taxYearHeader {
		text-align: left;
	}
	.fileDeck,
	.buttonDeck {
		flex-direction: column;
	}
	.buttonDeck {
		width: calc(100% - 1rem);
	}
	.taxContainer_annual {
		margin-bottom: 1rem;
	}
	.buttonDeck .button {
		margin-top: 1rem;
	}
	.page.myAccount .card-body {
		padding: 1.5rem 0 0.5rem;
	}
	.taxReview h2 {
		margin-bottom: 1.5rem;
	}
	.taxReview .taxContainer_annual {
		border-radius: 0;
		margin: 0 0 1px;
	}
}
/* Global Navigation menu - updated 23/1/2020 */

.productTaskbar .desktopWrapper {
	position: relative;
}
.globalNav {
	height: var(--masthead);
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.globalNavigation {
	position: absolute;
	top: var(--masthead);
	right: 0;
	background-color: white;
	min-width: 12em;
	border: 1px solid var(--paleCharcoal);
	border-top: none;
	transition: 0.5s;
	overflow: hidden;
	border-bottom: none;
}
.globalNavigation a,
a:not([href]):not([tabindex]) {
	cursor: pointer;
	color: var(--charcoal);
}
.globalNavigation a {
	display: block;
	padding: 1rem 1.5rem;
	border-top: 1px solid var(--paleCharcoal);
}
.globalNavigation section:last-child {
	border-bottom: 1px solid var(--paleCharcoal);
}
.globalNavigation a:hover,
a:not([href]):not([tabindex]):hover {
	cursor: pointer;
	color: var(--charcoal);
}
.globalNavigation a:hover {
	background-color: var(--paleCharcoal_30);
	text-decoration: none;
}
.globalNav .hamburger {
	padding: 0;
	margin: 0 !important;
}
.globalNav .notificationIcon {
	display: inline-flex;
	background-color: var(--red);
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	color: white;
	margin-right: 0.5rem;
	font-family: consolas;
	line-height: 1.5rem;
}

/* expansion rules, with hamburger(!) */

.hamburger + .globalNavigation {
	max-height: 0;
}
.hamburger.is-active + .globalNavigation {
	max-height: calc(100vh - var(--masthead));
	/* border-top:1px solid var(--paleCharcoal) */
}
/* media query - mobile */

@media only screen and (max-width: 480px) {
	.globalNavigation {
		right: 0;
		border: none;
		width: 100%;
		background-color: var(--paleCharcoal_30);
	}
	.panel-footer.card-footer {
		padding: 0.75rem 1.5rem !important;
	}
	button,
	input[type="button"],
	.button,
	.btn {
		max-height: 2rem;
	}
	.button.sv_prev_btn {
		background-size: 18px;
		background-position: left 0.4rem center;
	}
	.button.sv_next_btn {
		background-size: 18px;
		background-position: right 0.4rem center;
	}
	.sv_q_file .sv_q_file_preview {
		padding: 1rem 0;
	}
	.panel-footer,
	.card-footer,
	.page-footer {
		padding: 1em !important;
	}
	.sv_row div[style*="width: 33.3333%"],
	.sv_row div[style*="width: 50%"] {
		width: 100% !important;
		padding: 0 !important;
	}
	.howtoNotes ul {
		padding: 0;
	}
}
/* Magic buttons */

.panel-footer > .button.btn-primary,
.panel-footer > .button.sv_next_btn,
.panel-footer > .button:last-child,
.page-footer > .button:last-child {
	margin-left: auto;
}
@media only screen and (max-width: 480px) {
	.panel-footer > .button {
		flex: 1;
		max-width: calc(50% - 0.75rem);
	}
}
/*info panel */

.infoPanel,
.infoPanel h5 {
	font-size: 14px !important;
	margin: 0;
}
.infoPanel {
	background-color: var(--paleCharcoal);
	padding: 1.5rem;
	margin-top: -1rem;
	margin-bottom: 0.75rem;
}
.infoPanel ul {
	margin-bottom: 0.5rem;
}
.infoPanel h5 {
	margin: 0 0 1em;
	font-weight: 700;
}
.infoPanel li {
	padding-bottom: 0;
}
/* Read only view */

/* Containers */

.readOnly .sv_p_container {
	background-color: var(--paleCharcoal_30);
	padding-top: 1.5rem !important;
	/*margin-top: 0 !important;*/
}
.readOnly .sv_p_container .sv_p_container {
	border-top: none !important;
}
.readOnly .sv_p_container .sv_row + .sv_row {
	/* border-top: none; */
	padding-top: 1.5rem;
}
.readOnly .sv_row .sv_qstn {
	padding-top: 0 !important;
}
.readOnly .sv_p_title {
	margin-bottom: 1rem !important;
	margin-top: 0 !important;
}
/* neutral inputs */

.readOnly textarea,
.readOnly input[type="text"],
.readOnly input[type="date"],
.readOnly input[type="number"] .sv_q_text_root,
.readOnly
	input:not([type="button"]):not([type="reset"]):not([type="submit"]):not(
		[type="image"]
	):not([type="checkbox"]):not([type="radio"]),
.readOnly .sv_main .sv_container .sv_body .sv_p_root .sv_q select,
.readOnly textarea,
.readOnly .form-control {
	background: transparent;
	padding: 0;
	line-height: 1.4em;
	border-radius: 0;
	height: auto;
	border: none;
	margin: 0;
}
.readOnly .form-control:disabled,
.readOnly .form-control[readonly] {
	padding: 0;
	min-height: 0;
	height: 1.5rem;
	background-color: transparent;
}
.readOnly
	h5[title="Please attach any relevant supporting documentation here:"] {
	display: none;
}
.readOnly .sv_q_file:before {
	content: none;
}
.readOnly label,
.readOnly input,
.readOnly textarea {
	cursor: default;
}
.readOnly textarea {
	background-color: white;
}
/* success criteria */

.readOnly .radio.checked,
.readOnly .checkbox.checked {
	display: block !important;
}
/* additional detail */

.readOnly .sv_q_file_preview:first-child {
	margin-top: 0;
	border-top: none;
	padding-top: 0.5rem;
}
.readOnly .sv_q_file {
	background-color: transparent;
	min-height: 0;
}
.readOnly .sv_row div[style*="width: 33.3333%"],
.readOnly .sv_row div[style*="width: 50%"] {
	padding-right: 0.75em !important;
	flex: none;
}
.readOnly .panel-body > div > h4:before,
.readOnly .panel-body > div > h4 + .small:before,
.readOnly .panel-body > div > h4:after,
.readOnly .panel-body > div > h4 + .small:after {
	background-color: var(--slate);
}
.readOnly .panel-body > div > h4 + .small {
	background-color: #506375;
}
/* progress bar */

/* :root .readOnly {
  --progressbar: 2rem;
}
.readOnly .sv_main .progress {
  background-color: #506375;
}
.readOnly .sv_main .progress-bar {
  background-color: transparent;
}
.readonly.page.survey {
  margin-top: 0;
}
.readOnly .progress:before {
  content: "Read-only view";
  font-size: 14px;
  color: var(--paleCharcoal);
  text-transform: uppercase;
  height: 2rem;
  line-height: 2rem;
  display: block;
  max-width: calc(960px - 3rem);
  margin: 0 auto;
  padding: 0 1.5rem;
  text-align: left;
  width: 100vw;
  font-family: var(--fontBold);
} */

:root .readOnly {
	--progressbar: 0;
}
.readOnly .progress-bar,
.readOnly .sv_progress_bar {
	display: none;
}
/* Survey Navigation */

:root {
	--surveySectionHead: 120px;
	--surveyNavigationHead: 4rem;
	--surveyNavColumn: calc(256px - 1.5rem);
}
.sectionNav:empty {
	display: none;
}
.page.survey {
	display: flex;
	flex-direction: row;
}
.page-footer:after,
.panel-body > div > h4 + .small:after,
.panel-body > div > h4:after,
.panel-footer:after {
	width: calc(50vw + 50%);
}
/* Desktop - large */

.sectionNav {
	background-color: transparent;
	margin-right: 1.5rem;
	width: var(--surveyNavColumn);
	position: static;
	box-shadow: none;
	z-index: 80;
}
.sectionNav header {
	height: var(--surveyNavigationHead);
	background-color: var(--paleCharcoal);
	padding: 0 1.5rem;
	margin: 0;
	margin-top: calc(var(--surveyHeader) - 4rem);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-top: 5px solid var(--slate);
}
.sectionsIcon {
	background-size: contain;
	width: 29px;
	height: 30px;
	background-repeat: no-repeat;
	background-image: url("icons/icon_files_dark.png");
}
.sectionNav header h2 {
	color: var(--charcoal);
	font-size: 13px;
	font-family: var(--fontBold);
	margin: 0 0 0 0.5rem;
	line-height: 1.2em;
}
.surveyNav {
	padding-top: 2rem;
}
.menuItem > * {
	border: none;
	transition: 0.2s;
	max-width: 100%;
	margin-left: -1.5rem;
	display: flex;
	align-items: center;
	padding: 0.75em 1.5rem;
	border-top: 1px solid rgb(101 124 145 / 50%);
	margin-left: 0;
	display: block;
	color: var(--charcoal);
}
.menuItem > *.active,
.menuItem > *:hover,
.menuItem > *.active + .menuItem > *,
.menuItem > *:hover + .menuItem > * {
	border-color: transparent;
}

.menuItem > *:hover,
.menuItem > *.active {
	text-decoration: none;
	background-color: var(--slate);
	cursor: pointer;
}
.menuItem > *:hover,
.menuItem > *.active,
.menuItem > *:hover a,
.menuItem > *.active a {
	color: white !important;
}
.menuItem:hover + .menuItem > * {
	border-top-color: var(--slate);
}
.menuItem > * .statusIcon {
	display: inline-block;
	min-width: 1.25rem;
	height: 1.25rem;
	background-color: var(--paleCharcoal);
	vertical-align: top;
	margin-right: 0.75rem;
	border-radius: 100%;
}
/* Edit income sources */

.button.editSources {
	max-width: 100%;
	background-color: var(--paleCharcoal_plus30);
	border: none;
	font-size: var(--small);
	font-size: 14px;
	display: flex;
	margin-top: 1.5rem;
	align-items: center;
}
.button.editSources:hover {
	background-color: var(--slate);
}
.button.editSources:before {
	content: "+";
	font-family: var(--fontBold);
	font-weight: 700;
	font-size: 18px;
	margin-right: 0.25rem;
	line-height: 1em;
	border: 2px solid white;
	border-radius: 0 !important;
	height: 20px;
	width: 20px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.button.editSources {
	transition: 0.2s;
	line-height: 1.4em;
}

.surveyNav:empty {
	display: none;
}
/* Desktop - small */

@media only screen and (max-width: 813px) {
	.page.survey {
		flex-direction: column;
	}
	.sv_main:before {
		content: "";
		display: block;
		height: var(--surveyNavigationHead);
		background-color: var(--charcoal);
		border-bottom: 1px solid var(--charcoal_50);
		position: fixed;
		left: 0;
		width: 100%;
		z-index: 79;
	}
	.sv_main > form {
		padding-top: var(--surveyNavigationHead);
	}
	.sectionNav {
		width: auto;
		min-width: 18rem;
		left: 0;
		top: calc(var(--masthead) + var(--progressBar));
		z-index: 80;
		position: fixed;
	}
	.sectionNav header {
		margin: 0;
		border: none;
		background: var(--charcoal);
		border-bottom: 1px solid var(--charcoal_50);
	}
	.sectionsIcon {
		background-image: url("icons/icon_files_white.png");
	}
	.sectionNav header h2 {
		color: var(--paleCharcoal);
	}
	.surveyNav {
		padding: 0;
		position: absolute;
		background-color: var(--paleCharcoal_30);
		width: 100%;
		max-width: 400px;
		font-size: 14px;
	}
	.menuItem > * {
		padding: 0.75em 1.5rem;
		border-top: none;
		border-bottom: 1px solid var(--paleCharcoal);
		display: block;
		color: var(--charcoal) !important;
	}
}
@media all and (max-width: 812px) and (min-width: 481px) {
	.surveyNav {
		display: none;
		box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
	}
	.sectionNav:hover .surveyNav {
		display: block;
	}
	.sectionNav header:hover {
		background-color: var(--slate);
		cursor: pointer;
	}
}
@media only screen and (max-width: 480px) {
	.sectionNav {
		min-width: 100%;
		right: 0;
		overflow-y: scroll;
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: none;
		max-height: calc(100vh - (var(--masthead) + var(--progressbar)));
		z-index: 1000;
	}
	.sectionNav::-webkit-scrollbar {
		width: 0 !important;
	}
	.sectionNav header:hover {
		background-color: inherit;
	}
	.sectionNavHeader_expanded {
		background: var(--slate) !important;
	}
	.surveyNav {
		border: none;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.3s ease-in-out;
		position: static;
	}
	.sectionNavHeader_expanded + .surveyNav {
		max-height: 1500px;
		overflow: visible;
	}
	.sectionNavHeader_expanded + .surveyNav .menuItem:last-child > * {
		border-bottom: 1px solid var(--paleCharcoal);
	}
	.sectionNavHeader_expanded + .surveyNav:before {
		content: "";
		background-color: var(--paleCharcoal_30);
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: calc(var(--masthead) + var(--progressbar));
		height: calc(100vh - (var(--masthead) + var(--progressbar)));
		z-index: -1;
	}
	.sectionNavHeader_expanded:after {
		content: "X";
		margin-left: auto;
		font-size: 23px;
		font-weight: 700;
		color: white;
		line-height: 1em;
		opacity: 0.5;
		width: 40px;
		text-align: center;
		transition: 0.3s;
	}
	.sectionNavHeader_expanded:hover:after {
		opacity: 1;
	}
}
/* Hamburger menu - global background */

.menuOverlay {
	position: fixed;
	top: var(--masthead);
	bottom: 0;
	left: 0;
	right: 0;
	height: 0;
	width: 100%;
	background-color: rgba(51, 51, 51, 0);
	transition: background-color 0.5s;
	z-index: -1;
}
.hamburger.is-active + .globalNavigation + .menuOverlay {
	height: 100%;
	background-color: rgba(51, 51, 51, 0.5);
}
/* Messaging - Remember to align with back-office */

.page.messages .card {
	border: none;
	background: transparent;
	display: block;
	border-radius: 0;
	margin-bottom: 0;
	padding-bottom: 0;
	position: relative;
	z-index: 1;
}
.page.messages .card:after {
	content: "";
	display: block;
	border-bottom: 1px solid var(--paleCharcoal);
}
.page.messages .card:last-of-type:after {
	border-bottom: none;
}
.page.messages .card:last-child .card-body {
	border: none;
	padding: 0;
}
.accordion > .card .card-header {
	padding: 0;
	background: none;
	border: none;
	position: relative;
	z-index: 1;
}
.accordion > .card .card-header button {
	background: none;
	border: none;
	width: 100%;
	text-align: left;
	color: var(--charcoal);
	justify-content: space-between;
	font-family: var(--fontBold);
	font-size: 1.15rem;
	padding: 1.5rem 0;
	min-height: 3rem;
	max-height: 5rem;
}
.accordion > .card .card-body {
	padding: 0 0 1.5rem;
}
.commsPanel {
	display: flex;
	margin: 0;
	flex-direction: column;
}
.commsPanel li:before {
	content: none;
}
.commsPanel .gridRow {
	width: 100%;
	margin: 0 0 1rem;
	padding: 0;
	display: flex;
}
.message {
	background-color: var(--paleCharcoal);
	padding: 1rem 1.5rem;
	flex: 1 1;
	border-radius: 5px;
	margin-right: 4.5rem;
	position: relative;
	font-size: 1rem;
	max-width: calc(100% - 9rem);
}
.message header {
	display: flex;
	justify-content: space-between;
}
.message .description {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}
.message article:empty {
	margin-top: 0;
}
.message article {
	margin-top: 1rem;
	/* white-space: pre-wrap; */
	/* max-height: 1.5em; */
	/* overflow: hidden; */
	transition: 0.5s;
}
.message article.collapsed {
	/* overflow: hidden; */
	/* text-overflow: ellipsis; */
	/* white-space: nowrap; */
	/* width: calc(100% - 10rem); */
}
.message article.expanded {
	max-height: 2000px;
}
.message article:before {
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		#e7e7e7 100%
	);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		var(--paleCharcoal) 100%
	);
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	margin: 0 -1.5rem;
	opacity: 0.8;
	content: none;
}
.message article.expanded:before {
	opacity: 0;
}
.bdoMessage .message article:before {
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		#008fd2 100%
	);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		var(--ocean) 100%
	);
	background: #008fd2;
	background: var(--ocean);
}
.messageDescription {
	flex: 1 1;
}
.message h4 {
	font-family: "Proxima Nova Bold", sans-serif;
	font-family: var(--fontBold);
	line-height: 1em;
	font-size: 1rem;
	padding: 0;
	width: calc(100% - 10rem);
	text-overflow: ellipsis;
	overflow: hidden;
	height: 1em;
	white-space: nowrap;
}
.message .date {
	font-size: 14px;
	text-align: left;
	padding: 0;
	opacity: 0.5;
}
.avatar {
	width: 3.5rem;
	min-width: 3.5rem;
	height: 3.5rem;
	background-color: var(--slate);
	margin: 0.5rem 1rem 0 0;
	border-radius: 100%;
	background-image: url(icons/Avatar_white.svg);
	background-size: 80%;
	background-repeat: no-repeat;
	background-position: 50% 14px;
}
.gridRow.systemMessage {
	color: #5b6e7f;
	color: var(--slate);
}
.gridRow.bdoMessage {
	flex-direction: row-reverse;
}
.gridRow.bdoMessage .avatar {
	margin: 0.5rem 0 0 1rem;
	background-color: #008fd2;
	background-color: var(--ocean);
}
.gridRow.bdoMessage .message {
	background-color: var(--ocean);
	color: white;
	margin: 0 0 0 4.5rem;
}
.gridRow.clientMessage {
	color: #21409a;
	color: #333333;
	color: var(--charcoal);
}
.gridRow.clientMessage .avatar {
	background-color: #21409a;
	background-color: #333333;
	background-color: var(--charcoal);
}
.gridRow.clientMessage .message {
	background-color: rgb(231, 231, 231);
}
.systemMessage .avatar {
	background-image: url(icons/email_white.svg);
	background-position: 50%;
	background-size: 60%;
}
.commsPanel button {
	background-color: var(--slate);
	width: auto;
	min-width: 0;
	width: 38px;
	height: 38px;
	background-size: 60%;
	background-position: 50%;
	background-repeat: no-repeat;
	min-height: 40px;
	padding: 0;
	border-radius: 0 !important;
}
.commsPanel button:hover {
	background-color: #5b6e7f;
	background-color: var(--ocean);
	opacity: 1;
	background-size: 70%;
}
.attachment {
	background-image: url(icons/attachment_white.svg);
}
.bdoMessage .attachment {
	background-image: url(icons/attachment_white.svg);
}
.message:before {
	content: "";
	display: block;
	position: absolute;
	left: -0.75rem;
	width: 0;
	height: 0;
	border-top: 0.5rem solid transparent;
	border-bottom: 0.5rem solid transparent;
	border-right: 0.75rem solid var(--paleCharcoal);
	top: 1.75rem;
}
.bdoMessage .message:before {
	content: none;
}
.bdoMessage .message:after {
	content: "";
	display: block;
	position: absolute;
	right: -0.75rem;
	width: 0;
	height: 0;
	border-top: 0.5rem solid transparent;
	border-bottom: 0.5rem solid transparent;
	border-left: 0.75rem solid #008fd2;
	border-left: 0.75rem solid var(--ocean);
	top: 1.75rem;
}
button.conversation {
	background-image: none;
	background: white;
	height: 1rem;
	padding: 0 0.4rem;
	width: 2.333rem;
	min-height: 1rem;
	border-radius: 0 !important;
	position: absolute;
	bottom: 1rem;
	z-index: 1;
	right: 1.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	display: none;
}
button.conversation:hover {
	opacity: 0.5;
	background-color: white;
}
.expDot {
	content: "";
	width: 0.333rem;
	height: 0.333rem;
	background-color: #5b6e7f;
	background-color: var(--slate);
	display: flex;
	border-radius: 100%;
}
.bdoMessage .expDot {
	background-color: #008fd2;
	background-color: var(--ocean);
}
.message article:empty {
	margin: 0;
	max-height: 0;
}
.message article:empty + button {
	display: none;
}
/* comms header and controls */

.page.messages {
	padding-top: 0;
}
.page.messages .commsHead {
	display: flex;
	border-bottom: 1px solid var(--paleCharcoal);
	margin-bottom: 0;
	height: 5rem;
	align-items: center;
}
.page.messages .commsHead h2 {
	padding: 0;
	border: none;
	margin: 0;
	flex: 1 1;
}
.page.messages button {
	border: none;
	margin-left: 1rem;
}
.page.messages .commsHead button {
	height: 2.5rem;
	padding: 0 1.5rem;
	padding-left: 3rem;
	width: auto;
	min-width: 0;
	background-image: url(icons/SpeechBubble_white.svg);
	background-size: 1.3rem;
	background-repeat: no-repeat;
	background-position: 1rem 0.7rem;
	background-color: var(--ocean);	
	border-radius: 0 !important;
}
.page.messages .commsHead button:hover {
	background-color: var(--slate);
}
.fileViewer .description a,
.fileViewer .description span {
	display: flex;
	align-items: center;
	font-family: var(--fontReg);
	color: inherit;
}
.fileViewer .description a:before,
.fileViewer .description span:before {
	content: "";
	display: block;
	margin-right: 1rem;
	text-align: center;
	background-image: url(icons/file.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-color: transparent;
	width: 60px;
	height: 64px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.fileViewer a[id*=".pdf"]:before,
.fileViewer a[id*=".PDF"]:before {
	background-image: url(icons/pdf.svg);
}
.fileViewer a[id*=".png"]:before,
.fileViewer a[id*=".jpg"]:before,
.fileViewer a[id*=".gif"]:before,
.fileViewer a[id*=".tiff"]:before,
.fileViewer a[id*=".svg"]:before,
.fileViewer a[id*=".bmp"]:before,
.fileViewer a[id*=".jpeg"]:before,
.fileViewer a[id*=".PNG"]:before,
.fileViewer a[id*=".JPG"]:before,
.fileViewer a[id*=".GIF"]:before,
.fileViewer a[id*=".TIFF"]:before,
.fileViewer a[id*=".SVG"]:before,
.fileViewer a[id*=".BMP"]:before,
.fileViewer a[id*=".JPEG"]:before {
	background-image: url(icons/img.svg);
}
.fileViewer a[id*=".xls"]:before,
.fileViewer a[id*=".xlsx"]:before,
.fileViewer a[id*=".numbers"]:before,
.fileViewer a[id*=".XLS"]:before,
.fileViewer a[id*=".XLSX"]:before,
.fileViewer a[id*=".NUMBERS"]:before {
	background-image: url(icons/xls.svg);
}
.fileViewer a[id*=".doc"]:before,
.fileViewer a[id*=".docx"]:before,
.fileViewer a[id*=".pages"]:before,
.fileViewer a[id*=".DOC"]:before,
.fileViewer a[id*=".DOCX"]:before,
.fileViewer a[id*=".PAGES"]:before {
	background-image: url(icons/doc.svg);
}
.fileViewer a[id*=".ppt"]:before,
.fileViewer a[id*=".pptx"]:before,
.fileViewer a[id*=".PPT"]:before,
.fileViewer a[id*=".PPTX"]:before {
	background-image: url(icons/ppt.svg);
}
.fileViewer .description {
	padding-bottom: 1rem;
	border-bottom: 1px solid var(--paleCharcoal);
	margin-bottom: 1rem;
}
.fileViewer .description {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid var(--paleCharcoal);
	margin: 0;
	background: white;
	transition: 0.3s;
}
.fileViewer .description:hover {
	cursor: pointer;
	background: var(--paleCharcoal_30);
}
.fileViewer .description:last-child {
	margin-bottom: 0;
	border: none;
}
/* Modal styling */

.fileViewer .modal-header {
	padding: 1rem 1.5rem;
}
.fileViewer .modal-body {
	padding: 0;
}
/* New timeline display */

.status.TRQ {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.viewQuestionnaire,
.fileRepository {
	display: flex;
	width: 100%;
	align-items: center;
	padding-bottom: 1rem;
}
.TRQ .button {
	min-height: 3rem;
	min-height: 40px;
	flex: 1;
}
.fileRepository {
	border-top: 1px solid #e7e7e7;
	padding-top: 1.5rem;
	padding-bottom: 0;
}
.TRQ .whatsHappening {
	font-size: 16px;
	font-family: var(--fontReg);
	line-height: 1.5em;
	padding-top: 1rem;
	border-top: 1px solid #e7e7e7;
}
.TRQ .whatsHappening.noMargin {
	margin-bottom: 0;
}
.TRQ .whatsHappening a {
	font-family: var(--fontBold);
	color: var(--ocean);
	text-decoration: underline;
}
.cd-timeline .status:before {
	content: none;
}
.viewQuestionnaire:before,
.fileRepository:before {
	content: "";
	display: inline-flex;
	width: 80px;
	height: 86px;
	margin-right: 0;
	background-image: url(icons/TRQ.svg);
	background-size: 100%;
	background-repeat: no-repeat;
	margin-right: 1rem;
	background-position: 50%;
}
.fileRepository:before {
	background-image: url(icons/folder.svg);
}
.viewQuestionnaire button {
	display: inline-flex;
}
/* Additional Files modal */
.additionalFiles .close{
	width:40px;
}
.additionalFilesBody{
	padding-top: 1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
	text-align: left;
}

.additionalFiles .submit:hover{
	background-color: var(--slate);
}
.additionalFiles .submit:disabled:hover{
	background-color: var(--ocean);
	cursor:unset;
}


.additionalFiles .close:hover{
	background-color: white;
}
.cancelUpload:hover{
	background-color: #444 ;
}
.cancelUpload {
	border: 1px solid var(--slate);
	background-color: var(--slate);
}

.additionalFiles input{
	margin-bottom: 10px;
}

.thankYouMessage{
	font-size: xxx-large !important;
    height: auto !important;
    line-height: normal;
    padding-right: 175px !important;
    padding-left: 175px !important;
	padding-top:150px !important;
	padding-bottom:50px !important;
}
.closeAdditionalFiles{
	margin-left: auto;
	margin-right: auto;
	margin-bottom:150px;
}

.additionalFilesMandatory{
	color:red;
}

.additionalFiles .modal-header,
.additionalFiles .modal-footer {
	padding: 1rem 1.5rem;
}
.additionalFiles .modal-body {
	padding: 0;
	flex-direction: column;
}
.additionalFiles .modal-footer {
	border-top: 1px solid var(--paleCharcoal);
}
.additionalFiles .description {
	width: 100%;
	display: flex;
	border-top: 1px solid var(--paleCharcoal);
	padding: 1rem 1.5rem;
}
.additionalFiles .description a {
	display: inline-flex;
	flex: 1;
	transition: 0.3s;
	font-family: var(--fontReg);
	color: var(--charcoal);
	align-items: center;
}
.additionalFiles .description a:hover {
	text-decoration: none;
}
.additionalFiles .description:first-child a {
	border: none;
}
.additionalFiles a:before {
	content: "";
	display: inline-flex;
	background-size: 100%;
	background-image: url(icons/file.svg);
	background-repeat: no-repeat;
	background-position: 50%;
	width: 60px;
	height: 63px;
	margin-right: 1rem;
}
.additionalFiles a[title*=".pdf"]:before,
.additionalFiles a[title*=".PDF"]:before {
	background-image: url(icons/pdf.svg);
}
.additionalFiles a[title*=".png"]:before,
.additionalFiles a[title*=".jpg"]:before,
.additionalFiles a[title*=".gif"]:before,
.additionalFiles a[title*=".tiff"]:before,
.additionalFiles a[title*=".svg"]:before,
.additionalFiles a[title*=".bmp"]:before,
.additionalFiles a[title*=".jpeg"]:before,
.additionalFiles a[title*=".PNG"]:before,
.additionalFiles a[title*=".JPG"]:before,
.additionalFiles a[title*=".GIF"]:before,
.additionalFiles a[title*=".TIFF"]:before,
.additionalFiles a[title*=".SVG"]:before,
.additionalFiles a[title*=".BMP"]:before,
.additionalFiles a[title*=".JPEG"]:before {
	background-image: url(icons/img.svg);
}
.additionalFiles a[title*=".xls"]:before,
.additionalFiles a[title*=".xlsx"]:before,
.additionalFiles a[title*=".numbers"]:before,
.additionalFiles a[title*=".XLS"]:before,
.additionalFiles a[title*=".XLSX"]:before,
.additionalFiles a[title*=".NUMBERS"]:before {
	background-image: url(icons/xls.svg);
}
.additionalFiles a[title*=".doc"]:before,
.additionalFiles a[title*=".docx"]:before,
.additionalFiles a[title*=".pages"]:before,
.additionalFiles a[title*=".DOC"]:before,
.additionalFiles a[title*=".DOCX"]:before,
.additionalFiles a[title*=".PAGES"]:before {
	background-image: url(icons/doc.svg);
}
.additionalFiles a[title*=".ppt"]:before,
.additionalFiles a[title*=".pptx"]:before,
.additionalFiles a[title*=".PPT"]:before,
.additionalFiles a[title*=".PPTX"]:before {
	background-image: url(icons/ppt.svg);
}
.additionalFiles .date {
	text-align: left;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 1.5rem;
}
.additionalFiles .date span {
	font-family: var(--fontBold);
	display: block;
}
.additionalFiles .noFilesFound {
	background: var(--paleCharcoal);
	display: flex;
	flex: 1;
	height: 16rem;
	margin: 0;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	font-family: var(--fontBold);
	text-transform: uppercase;
	color: rgba(51, 51, 51, 0.5);
}
.menuAvatar {
	display: none;
}
/* Tabbed nav new activity container - 7/4/2021 */

.PTAContainer {
	display: flex;
	width: 100%;
	flex-direction: column;
}
.settings {
	margin-top: auto;
	background: rgb(0 0 0 / 30%);
}
.settings h3 {
	border: none;
	margin-bottom: 1.5em;
}
.clientActions h2 {
	/* font-size: 1rem; */
	text-transform: none;
	/*font-family: var(--fontRegular);*/
}
.PTAContainer > header {
	background: var(--slate);
	color: rgb(255 255 255);
}
.PTAContainer > header h2 {
	padding: 0;
}
.PTAContainer > header h2 > span,
.PTAContainer > header h3 > span {
	color: rgb(255 255 255 / 0.5);
}
.PTAContainer > header h3 {
	font-family: var(--fontBold);
	font-size: 1rem;
}
.annualID {
	padding: 1.5rem 1.5rem;
}
.PTAContainer > header > nav {
	background-color: rgb(0 0 0 / 15%);
}
.PTAContainer > header > nav button {
	border: none;
	background-color: rgb(101 124 145 / 50%);
	font-family: var(--fontRegular);
	color: white;
	padding: 0.5rem 2.5rem;
}
.PTAContainer > header > nav button.selectedYear {
	background-color: var(--slate);
}
/* Desktop global navigation */

@media screen and (min-width: 912px) {
	.globalNav {
		width: calc(100% - 220px);
		align-items: flex-start;
	}
	.globalNav .hamburger {
		display: none;
	}
	.hamburger + .globalNavigation {
		max-height: 1000px;
	}
	.globalNavigation {
		position: static;
		border: none;
		display: flex;
		flex-direction: row;
		height: 100%;
	}
	.taxReturnPage,
	.messagesPage {
		border-left: 1px solid var(--paleCharcoal);
	}
	.messagesPage {
		border-right: 1px solid var(--paleCharcoal);
	}
	.globalNavigation section:last-child {
		border-bottom: none;
	}
	.taxReturnPage,
	.messagesPage {
		display: flex;
	}
	.globalNavigation > section > a {
		font-family: var(--fontRegular);
		height: 100%;
		border-top: 0.333rem solid rgb(101 124 145 / 20%);
		flex: 1;
		align-items: center;
		justify-content: center;
		display: flex;
		font-size: 14px;
	}
	.globalNavigation > section > a:hover {
		border-top-color: var(--ocean);
	}
	.globalNavigation > section.active > a {
		border-color: var(--red);
	}
	.taxReturnPage.active > a {
		background: var(--paleCharcoal);
	}
	.userSection {
		flex-direction: column;
		display: flex;
		font-size: 14px;
		line-height: 1.4em;
		padding-right: 0.5rem;
	}
	.userEmail {
		font-family: var(--fontBold);
		color: var(--charcoal);
	}
	.userMobileView {
		display: none;
	}
	.utilities {
		margin-left: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.utilities .subMenu {
		position: absolute;
		right: 1.5rem;
		top: var(--masthead);
		background-color: white;
		min-width: 12rem;
		overflow: hidden;
		max-height: 0;
		transition: 0.5s;
	}
	.menuAvatar {
		position: relative;
		top: 0;
		height: var(--mastHead);
		display: flex;
		height: 100%;
		width: 2rem;
		align-items: center;
		justify-content: flex-end;
		transition: 0.3s;
		opacity: 0.5;
	}
	.menuAvatar:before {
		content: "";
		display: block;
		height: 2rem;
		width: 2rem;
		background: var(--charcoal);
		border-radius: 100%;
		background-image: url(icons/Avatar_white.svg);
		background-size: 80%;
		background-repeat: no-repeat;
		background-position: 50% 120%;
		border: 2px solid var(--charcoal);
	}
	.utilities:hover {
		cursor: pointer;
	}
	.utilities:hover .subMenu {
		max-height: 700px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
	}
	.utilities:hover .menuAvatar {
		opacity: 1;
	}
}

@media screen and (max-width: 912px) {
	.userMobileView {
		display: block;
		text-align: center;
		font-weight: bold;
		border-bottom: black 1px solid;
		color: var(--charcoal);
		padding: 1rem 0 1rem 0;
	}
	.userSection {
		display: none;
	}
}
/* End media query */

/* surveyJS oddity over-ride */

.sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn .radio label,
.sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn .checkbox label {
	line-height: inherit !important;
	vertical-align: middle !important;
}
/* 05/04/2022 */
.page.messages .card .collapse:not(.show) {
	display: block;
}
.page.messages .card .collapse:not(.show) .card-body {
	display: none;
}
.page.messages .card .collapse::before {
	display: block;
	content: "";
	width: 1.5rem;
	height: 1.5rem;
	transition: 0.5s;
	transform: rotate(0);
	z-index: 0;
	position: absolute;
	top: 1.5rem;
	right: 0;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEyNiIgaGVpZ2h0PSIyMDQ2IiB2aWV3Qm94PSIwIDAgMTEyNiAyMDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTUxLjUgMjA0NS41TDAuNSAxODc2LjVMODAyIDEwMjVMMC41IDE3MEwxNTEuNSAwTDExMjUuNSAxMDI1TDE1MS41IDIwNDUuNVoiIGZpbGw9IiMzMzMzMzMiLz4KPC9zdmc+Cg==");
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 0.5;
}
.page.messages .card .collapse.show::before {
	transform: rotate(90deg);
	opacity: 1;
}
.page.messages .pageContent {
	padding-top: 0;
}
/* Additional file bug resolution 26/04/2022 */
.modal {
	padding: 0 !important;
}
.additionalFiles .modal-header {
	display: flex;
	align-items: center;
	line-height: 1em;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.modal-header .close {
	height: auto;
	text-shadow: none;
	box-sizing: unset;
}
.additionalFiles .modal-body {
	padding-bottom: 1.5rem;
}
.additionalFiles .modal-footer:empty {
	display: none;
}
.additionalFiles .modal-body > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	padding: 1rem 1rem 1rem calc(60px + 2rem);
	align-items: flex-start;
	font-size: 14px;
	height: calc(63px + 2rem);
	border-bottom: 1px solid var(--paleCharcoal);
}
.additionalFiles .modal-body > div:last-of-type {
	border: none;
}
.additionalFiles a {
	display: block;
	color: var(--charcoal);
	font-family: var(--fontReg);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	text-align: left;
	font-size: 1rem;
	font-weight: 600;
}
.additionalFiles a:before {
	position: absolute;
	top: 1rem;
	left: 1rem;
}
.autoSaveMessageFooter{
	justify-content: center;
}
.autoSaveTextTR{
	margin-left:35%;
	margin-right:35%;
	position: fixed;
	bottom: 1rem;
	max-width: 18rem;
	height: 48px;
	line-height: 48px;
	left: 2rem;
	z-index: 900;
	color:white;
}
/* Medical expenses archive */
.sv_q_file:has(input[disabled][type='file']):before{
	background-color: #999999!important;
	cursor:default;
}
.sv_q_file:has(input[disabled][type='file']):before:hover{
	background-color: #999999!important;
	cursor:default;
}

.medicalArchive:before {
	content: "Your medical expenses history";
	font-size: 1.75rem;
	font-family: var(--fontRegular);
	line-height: 1em;
	padding: 1.5rem 0;
	height: 4.5rem;
	margin-bottom: 1.5rem;
}
.medicalArchive:after {
	display: block;
	content: "";
	background-color: rgba(161, 161, 161, 0.33);
	z-index: -1;
	position: absolute;
	top: 6rem;
	height: calc(100% - 14rem);
	width: 4px;
	margin-left: -2px;
}
.medicalArchive,
.medCard {
	position: relative;
}
.medCard .whatsHappening{
	border-top:1px solid #e7e7e7;
	padding-top: 1rem;
	font-family: var(--fontReg);
    font-size: 16px;
	font-weight: 700;
}
.medicalArchive {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.medQuestionnaireLink{
	cursor:pointer;
}
.medQuestionnaireLink:hover{
	text-decoration:none;
}
.medContainer_annual {
	display: flex;
	flex-direction: row;
	width: calc(50% + 2rem);
	z-index:2;
	cursor:default;
}
.medContainer_annual > header > div {
	background: var(--slate);
	color: white;
	font-size: 14px !important;
	font-weight: 300;
	font-family: var(--fontRegular);
	padding: 0.5rem;
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	border: 0.25rem solid white;
	box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
	

background-position: 50%;
    background-repeat: no-repeat;
    background-size: 65%;
}
.medicalExpenseStatus{
	background-color: var(--ocean)!important;
	width:100px;
	color:white;
	text-align: center;
	border-radius:2px;
}
.medExpenseYearHeader{
	background-color: var(--paleCharcoal) !important;
	z-index:1;

}
.medExpenseYearHeader > h2{
	font-size: 50px;
}
.medExpenseComplete{
	background-color: var(--ocean) !important;
	background-image: url(icons/verified.svg) !important;
}
.medExpenseOpen{
	background-color: var(--paleCharcoal)!important;
}
.medExpenseStarted{
	background-color: var(--slate)!important;
	background-image: url(icons/timelineSpinner.svg)!important;
}
.medCard {
	background-color: white;
	padding: 1.5rem;
	flex: 1;
	margin-bottom: 2.5rem;
	border-top: 0.5rem solid var(--slate);
	box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
	margin-left: 2.5rem;
	margin-top: -0.5rem;
}
.medCardComplete{
	border-top: 0.5rem solid var(--ocean) ;
}
.medCardPrimaryComplete{
	padding-bottom:1rem;
}
.medCard:before {
	content: "";
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	border-top: 1rem solid transparent;
	border-bottom: 1rem solid transparent;
	border-right: 1.5rem solid white;
	left: -1.5rem;
	top: 1rem;
}

.medCard .file {
	margin-right: 1.5rem;
	background-position: top left;
	min-width: 60px;
	min-height: 70px;
	background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyODcgMzA2Ij4KICA8cGF0aCBkPSJNMjU3LjcgMTUyLjlWNzYuNUwxODEuNCAwSDI4LjV2MzA1LjdoMjI5LjJsLjMtNTcuMy0uMy05NS41WiIgZmlsbD0iI0VEMUEzQiIvPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTcyLjEgODUuOVY5LjZoLTEzNHYxMDcuM2gyMTAuMnYtMzFoLTc2LjJabTkuMy03M3Y2My42SDI0NWwtNjMuNi02My42Wk0zOC4xIDI5Ni4xaDIxMC4ydi00Ny44SDM4LjF2NDcuOFoiIGZpbGw9IiNmZmYiLz4KICA8Y2lyY2xlIGN4PSIxNDMiIGN5PSIxODIiIHI9Ijc2IiBmaWxsPSIjRUQxQTNCIi8+CiAgPGNpcmNsZSBjeD0iMTQzIiBjeT0iMTgyIiByPSI2OSIgZmlsbD0iI2ZmZiIvPgogIDxwYXRoIGQ9Ik0xMjUuOTc2IDE0MXYyMy45NzZIMTAydjM0LjA0OGgyMy45NzZWMjIzaDM0LjA0OHYtMjMuOTc2SDE4NHYtMzQuMDQ4aC0yMy45NzZWMTQxaC0zNC4wNDhaIiBmaWxsPSIjRUQxQTNCIi8+Cjwvc3ZnPgo=");
}
.medCard > section {
	display: flex;
}
.medCard .taxFileYourName {
	color: var(--slate);
	font-weight: 400;
	line-height: 1em;
	font-size: 1rem;
	margin-bottom: 0.5em;
}
.medFileIcon{
	min-width:80px!important;
}
.primarySection{
	width:100%;
}
.medDetails{
	width:100%;
	display:flex;
}
.medDate{
	margin-left:auto;
	height: 15px;
	line-height: 15px;
	font-size:20px;
}
.medPracticeName {
	font-family: var(--fontBold);
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 1;
}
.medCard .statusMessage > span {
	text-transform: lowercase;
	font-weight: 600;
}
.card-footer:empty {
	display: none;
}
/* Alternating styles */
div.medContainer_annual:nth-of-type(odd) {
	margin-right: calc(50% - 1.5rem);
	flex-direction: row-reverse;
}
div.medContainer_annual:nth-of-type(odd) .medCard:before {
	left: unset;
	right: -1.5rem;
	border-left: 1.5rem solid white;
	border-right: none;
}
div.medContainer_annual:nth-of-type(odd) .medCard {
	margin-left: unset;
	margin-right: 2.5rem;
}
div.medContainer_annual:nth-of-type(even) {
	margin-left: calc(50% - 2rem);
}
.scrollHighlightDiv {
	background: #5b6e7f !important;
}
.scrollHighlightText {
	color: #ffff !important;
}
@media only screen and (max-width: 1023px) {
	/* Small screen */
	.medicalArchive {
		align-items: flex-start;
		padding: 0 1.5rem;
		background-color: var(--paleCharcoal);
		margin-bottom: -2rem;
	}
	.medicalExpensesProcess + .medicalArchive:before {
		padding: 0;
		font-size: 1.5rem;
		margin: 0;
	}
	.medicalArchive:after {
		margin-left: calc(2rem - 4px);
		z-index: 0;
	}
	.medContainer_annual > header > div {
		position: relative;
		z-index: 1;
	}
	.medContainer_annual {
		width: 100%;
	}
	.medCard,
	div.medContainer_annual:nth-of-type(odd) .medCard {
		margin-right: unset;
		margin-left: 2.5rem;
	}

	div.medContainer_annual:nth-of-type(odd) {
		margin-right: unset;
		flex-direction: unset;
	}
	div.medContainer_annual:nth-of-type(odd) .medCard:before {
		right: unset;
		left: -1.5rem;
		border-right: 1.5rem solid white;
		border-left: none;
	}
	div.medContainer_annual:nth-of-type(odd),
	div.medContainer_annual:nth-of-type(even) {
		margin-left: 0;
	}
}
@media only screen and (max-width: 812px) {
	.medCard {
		max-width: 100%;
	}
}
@media only screen and (max-width: 480px) {
	section.medCardPrimary {
		display: block;
		width:100%;
	}
	.medCard .file {
		float: left;
	}
	.statusMessage {
		clear: both;
		padding-top: 1rem;
	}
	.medCard .taxFileYourName {
		line-height: 1.5em;
	}
	.medPracticeName {
		font-size: 1rem;
	}
}
/* laptop/small screen breakpoint */

@media only screen and (max-width: 768px) {
	.page.messages .commsHead {
		padding: 1rem 1.5rem;
		justify-content: space-between;
	}
	.page.messages .commsHead h2 {
		font-size: 1rem;
	}
	.page.messages .commsHead button {
		font-size: 14px;
		height: 40px;
		min-height: 40px;
	}
	.gridRow .message {
		max-width: 100%;
		width: 100%;
		margin: 0;
		padding: 1em;
		font-size: 14px;
	}
	.message .description {
		flex-direction: column;
		align-items: flex-start;
	}
	.message h4 {
		margin-bottom: 0.25em;
	}
}
